import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import {
  Transaction,
  Bank,
  Invoice,
  InvoiceEnqueue,
  ProcessSchedule,
} from "../models/transactions.model"
import dayjs, { Dayjs } from "dayjs"

export type FilterType = {
  dateSelector: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  search: string
  isMarked: string
  accountId: string
  sortBy: string
  orderBy: string
  transactionType: string
  paymentMethod: string
  operator: string
}

export type PaginationType = {
  page: number
  pageSize: number
}

export type reconcileType = {
  amount: number
  invoiceNumber: string
  tdsDeducted: boolean
  tdsAmountWithHeld: number
  tdsPercentage: number
  invoiceId: string
}[]

type TransactionsState = {
  transactionList: Transaction[]
  bankList: Bank[]
  isLoading: boolean
  pageInfo: {
    totalRowCount: number
  }
  pagination: PaginationType
  isUploading: boolean
  isDownloading: boolean
  isBankListLoading: boolean
  paymentMethods: { key: string; value: string }[]
  isPaymentMethodsLoading: boolean
  filters: FilterType
  isInvoiceDetailsLoading: boolean
  invoiceDetails: Invoice
  reconcile: reconcileType
  isPostReconcileDataLoading: boolean
  postReconcileSuccess: boolean
  processScheduleData: ProcessSchedule
  isProcessScheduleLoading: boolean
}

export type getTransactionListPayloadType = {
  payload: {
    page: number
    pageSize: number
    startDate?: string
    endDate?: string
    search: string
    accountId: string
    isMarked: string
    sortBy: string
    orderBy: string
    transactionType: string
    paymentMethod: string
    operator: string
  }
}

export type getInvoiceDetailsPayloadType = {
  payload: {
    values: { reconcile: reconcileType }
    index: number
  }
}

export type uploadCSVFilePayloadType = {
  payload: {
    file: File
    accountId: string
  }
}

type postReconciledDataType = {
  payload: {
    transaction_id: string
    reconcile: InvoiceEnqueue[]
  }
}

const initialProcessScheduleState = {
  message: "",
  status: false,
  nextRun: "",
  scheduleStatus: "",
  id: "",
  name: "",
}

export const initialFilterState = {
  dateSelector: "month",
  startDate: dayjs().startOf("month"),
  endDate: dayjs(),
  search: "",
  accountId: "all",
  isMarked: "all",
  sortBy: "date",
  orderBy: "desc",
  transactionType: "all",
  paymentMethod: "all",
  operator: "",
}

export const initialPaginationState = {
  page: 0,
  pageSize: 10,
}

const intialInvoiceDetailsState = {
  zohoInvoiceId: "",
  companyName: "",
  customerId: "",
  customerName: "",
  date: "",
  dueDate: "",
  email: "",
  invoiceId: "",
  invoiceNumber: "",
  status: "",
  balance: Infinity,
  total: 0,
  source: "",
  tds2Percent: "",
  tds10Percent: "",
}

const initialReconcilesState = [
  {
    amount: Infinity,
    invoiceNumber: "",
    tdsDeducted: false,
    tdsAmountWithHeld: 0,
    tdsPercentage: 10,
    invoiceId: "",
  },
]

export const transactionsInitialState: TransactionsState = {
  transactionList: [],
  isLoading: false,
  pagination: initialPaginationState,
  pageInfo: {
    totalRowCount: 0,
  },
  isUploading: false,
  isDownloading: false,
  bankList: [],
  isBankListLoading: false,
  paymentMethods: [],
  isPaymentMethodsLoading: false,
  filters: initialFilterState,
  isInvoiceDetailsLoading: false,
  invoiceDetails: intialInvoiceDetailsState,
  reconcile: initialReconcilesState,
  isPostReconcileDataLoading: false,
  postReconcileSuccess: false,
  processScheduleData: initialProcessScheduleState,
  isProcessScheduleLoading: false,
}

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: transactionsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTransactionList: (state, action: getTransactionListPayloadType) => {
      state.isLoading = true
    },
    getTransactionListSuccess: (
      state,
      {
        payload,
      }: { payload: { transactionList: Transaction[]; totalRowCount: number } },
    ) => {
      state.transactionList = payload.transactionList
      state.pageInfo.totalRowCount = payload.totalRowCount
      state.isLoading = false
    },
    getTransactionListFailed: (state) => {
      state.isLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadCSVFile: (state, action: uploadCSVFilePayloadType) => {
      state.isUploading = true
    },
    uploadCSVFileSuccess: (state) => {
      state.isUploading = false
    },
    uploadCSVFileFailed: (state) => {
      state.isUploading = false
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadCSVFile: (state, action: getTransactionListPayloadType) => {
      state.isDownloading = true
    },
    downloadCSVFileSuccess: (state) => {
      state.isDownloading = false
    },
    downloadCSVFileFailed: (state) => {
      state.isDownloading = false
    },
    getBankList: (state) => {
      state.isBankListLoading = true
    },
    getBankListSuccess: (
      state,
      { payload }: { payload: { bankList: Bank[] } },
    ) => {
      state.bankList = payload.bankList
      state.isBankListLoading = false
    },
    getBankListFailed: (state) => {
      state.isBankListLoading = false
    },

    getPaymentMethods: (state) => {
      state.isPaymentMethodsLoading = true
    },
    getPaymentMethodsSuccess: (
      state,
      { payload }: { payload: { paymentMethods: [] } },
    ) => {
      state.paymentMethods = payload.paymentMethods
      state.isPaymentMethodsLoading = false
    },
    getPaymentMethodsFailed: (state) => {
      state.isPaymentMethodsLoading = false
    },

    setFilters: (state, { payload }) => {
      state.filters = payload
      state.pagination.page = 0
    },
    setFiltersFromURL: (state, { payload }) => {
      state.filters = payload
    },
    resetFilters: (state) => {
      state.filters = initialFilterState
      state.pagination = initialPaginationState
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getInvoiceDetails: (state, action: getInvoiceDetailsPayloadType) => {
      state.isInvoiceDetailsLoading = true
    },
    getInvoiceDetailsSuccess: (
      state,
      { payload }: { payload: { invoiceDetails: Invoice } },
    ) => {
      state.isInvoiceDetailsLoading = false
      state.invoiceDetails = payload.invoiceDetails
    },
    getInvoiceDetailsFailed: (state) => {
      state.isInvoiceDetailsLoading = true
    },
    resetInvoiceDetails: (state) => {
      state.isInvoiceDetailsLoading = true
      state.invoiceDetails = intialInvoiceDetailsState
      state.reconcile = initialReconcilesState
      state.postReconcileSuccess = false
    },
    setReconcile: (
      state,
      { payload }: { payload: { reconcile: reconcileType } },
    ) => {
      state.reconcile = payload.reconcile
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    postReconciledData: (state, action: postReconciledDataType) => {
      state.isPostReconcileDataLoading = true
    },
    postReconciledDataSuccess: (state) => {
      state.isPostReconcileDataLoading = false
      state.postReconcileSuccess = true
    },
    postReconciledDataFailed: (state) => {
      state.isPostReconcileDataLoading = false
    },
    getProcessSchedule: (state) => {
      state.isProcessScheduleLoading = true
    },
    initiateProcessSchedule: (state) => {
      state.isProcessScheduleLoading = true
    },
    getProcessScheduleSuccess: (
      state,
      {
        payload,
      }: {
        payload: {
          processScheduleData: ProcessSchedule
        }
      },
    ) => {
      state.processScheduleData = payload.processScheduleData
      state.isProcessScheduleLoading = false
    },
    getProcessScheduleFailed: (state) => {
      state.isProcessScheduleLoading = false
    },
  },
})

export const {
  getTransactionList,
  getTransactionListSuccess,
  getTransactionListFailed,
  uploadCSVFile,
  uploadCSVFileSuccess,
  uploadCSVFileFailed,
  downloadCSVFile,
  downloadCSVFileSuccess,
  downloadCSVFileFailed,
  getBankList,
  getBankListSuccess,
  getBankListFailed,
  getPaymentMethods,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailed,
  setFilters,
  setFiltersFromURL,
  resetFilters,
  setPagination,
  getInvoiceDetails,
  getInvoiceDetailsSuccess,
  getInvoiceDetailsFailed,
  resetInvoiceDetails,
  setReconcile,
  postReconciledData,
  postReconciledDataSuccess,
  postReconciledDataFailed,
  getProcessSchedule,
  initiateProcessSchedule,
  getProcessScheduleSuccess,
  getProcessScheduleFailed,
} = transactionsSlice.actions

export const selectTransactions = (state: RootState) => state.transactions

export default transactionsSlice.reducer
