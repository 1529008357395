import styled from "@emotion/styled"

// Media Query for small screens (e.g., mobile)
const smallScreen = "@media (max-width: 768px)"

export const CenterDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 200px 600px;
  gap: 20px 0px;
  margin: 16px 0px 20px 0px;
  max-width: 100%;
  word-wrap: break-word;

  > :nth-child(odd) {
    font-weight: 600;
  }

  ${smallScreen} {
    grid-template-columns: 1fr;
    gap: 0;
    > :nth-child(odd) {
      margin-top: 10px;
    }
  }
`

export const FixedButtons = styled.div`
  position: absolute;
  top: 24px;
  right: 110px;
  z-index: 1000;

  ${smallScreen} {
    position: static;
    text-align: center;
    margin-top: 20px;
  }
`

export const GoogleMapImage = styled.img`
  height: 200px;
  border-radius: 8px;
  border: 1px solid #dedede;
  cursor: pointer;

  ${smallScreen} {
    width: 100%;
    height: 160px;
  }
`
