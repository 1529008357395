import axios, { AxiosInstance } from "axios"
import { store } from "./store"
import { loginSuccess, logout } from "../slices/loginSlice"
import { showToast } from "../slices/toastSlice"
import PackageJSON from "../../package.json"
import { showDialog } from "../containers/VersionDialog/slice"
import { BASE_API_V1, USERS_REFRESH_TOKEN_API } from "../constants/apis"

type AccessTokenResponse = {
  data: {
    access_token: string
    user: {
      first_name: string
      last_name: string
      roles: Array<{ name: string }>
    }
    refresh_token: string
  }
}

const generateAccessToken = async (axiosInstance: AxiosInstance) => {
  try {
    const refreshToken = localStorage.getItem("refreshToken")

    if (refreshToken) {
      const { data }: { data: AccessTokenResponse } = await axiosInstance.post(
        USERS_REFRESH_TOKEN_API,
        {
          token: refreshToken,
        },
      )
      if (data?.data?.access_token) {
        const { user, access_token, refresh_token } = data.data
        const name = `${user?.first_name} ${user?.last_name}`
        localStorage.setItem("jwt", access_token)
        localStorage.setItem("role", user?.roles[0]?.name)
        localStorage.setItem("name", name)
        localStorage.setItem("refreshToken", refresh_token)

        store.dispatch(
          loginSuccess({
            token: access_token,
            role: user?.roles[0]?.name,
            name,
          }),
        )
      }
    }
  } catch (error) {
    /* empty */
  }
}

const { version } = PackageJSON

// created new axios instance
const newAxiosInstance = axios.create({
  baseURL: BASE_API_V1,
})

newAxiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("jwt")

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers["web-app-version"] = version
  return config
})

const axiosConfig = axios.create({
  baseURL: BASE_API_V1,
})

axiosConfig.interceptors.request.use((config) => {
  const token = localStorage.getItem("jwt")

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers["web-app-version"] = version
  return config
})

axiosConfig.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      if (error?.response?.data?.data?.type === "token_expired") {
        try {
          // Generate new access token using the provided function
          await generateAccessToken(newAxiosInstance)

          // Retry the failed request with the new Axios instance
          const retryResponse = await newAxiosInstance(error.config)

          // Return the successful response
          return Promise.resolve(retryResponse)
        } catch (refreshError) {
          // If refreshing token fails, logout
          store.dispatch(logout())
        }
      } else {
        store.dispatch(logout())
      }
    }

    if (error.response.status === 426) {
      store.dispatch(showDialog())
    }

    if (error.response.status !== 401) {
      console.error(error)
      store.dispatch(
        showToast({
          toastType: "error",
          msg:
            error?.response?.data?.data?.message ||
            error?.response?.data?.data?.["body.google_maps_url"]?.message ||
            error?.response?.data?.message ||
            "Something went wrong",
        }),
      )
    }

    return Promise.reject(error)
  },
)

export default axiosConfig
