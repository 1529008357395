import { takeLatest, put, call } from "redux-saga/effects"
import {
  fetchBookingDetailsService,
  fetchDaypassBookingService,
} from "../services/bookingHistoryService"
import {
  fetchBookingList,
  fetchBookingListSuccess,
  fetchBookingListFailed,
  fetchBookingListPayloadType,
  fetchBookingDetails,
  fetchBookingDetailsSuccess,
  fetchBookingDetailsFailed,
  fetchBookingDetailsPayloadType,
} from "../slices/bookingHistorySlice"
import { AxiosResponse } from "axios"

export function* fetchBookingListSaga(action: fetchBookingListPayloadType) {
  try {
    const { data }: AxiosResponse = yield call(
      fetchDaypassBookingService,
      action,
    )
    yield put(
      fetchBookingListSuccess({
        bookingList: data?.data || [],
        totalPages: data?.pagination?.pages || 0,
      }),
    )
  } catch (err) {
    yield put(fetchBookingListFailed())
  }
}

export function* fetchBookingDetailsSaga(
  action: fetchBookingDetailsPayloadType,
) {
  try {
    const { data } = yield call(fetchBookingDetailsService, action)
    yield put(fetchBookingDetailsSuccess({ bookingDetails: data.data }))
  } catch (err) {
    yield put(fetchBookingDetailsFailed())
  }
}

export default function* bookingHistorySaga() {
  yield takeLatest(fetchBookingList, fetchBookingListSaga)
  yield takeLatest(fetchBookingDetails, fetchBookingDetailsSaga)
}
