import { useEffect } from "react"

import { MainContainer, ParentContainer } from "./styled"
import Sidemenu from "../../components/Sidemenu/Sidemenu"
import AppRoutes from "../../app/routes"
import Toast from "../Toast"
import VersionDailog from "../VersionDialog"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { fetchCenters } from "../../slices/centersSlice"

export default function Layout() {
  const dispatch = useAppDispatch()
  const { token, role } = useAppSelector((state) => state.login)

  useEffect(() => {
    if (token) dispatch(fetchCenters())
  }, [dispatch, token])

  return (
    <ParentContainer>
      {token && <Sidemenu />}
      <MainContainer>
        <AppRoutes token={token} role={role} />
      </MainContainer>
      <Toast />
      <VersionDailog />
    </ParentContainer>
  )
}
