import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"

type meetingRoomType = {
  id: string
  uuid: string
  name: string
  amenities: string[]
}

type MeetingRoomsState = {
  meetingRooms: meetingRoomType[]
  isMeetingRoomLoading: boolean
  centerId: string
}

export const meetingRoomsInitialState: MeetingRoomsState = {
  meetingRooms: [],
  isMeetingRoomLoading: false,
  centerId: "",
}

export type fetchMeetingRoomsPayloadType = {
  payload: {
    centerId: string
  }
}

export type addMeetingRoomPayloadType = {
  payload: {
    centerId: string
    meetingRoomName: string
    seatCount: string
    pricePerHour: number
    amenities: string[]
    files: File[]
    description: string
    rules: string
  }
}

export type updateMeetingRoomPayloadType = {
  payload: {
    centerId: string
    meetingRoomId: string
    meetingRoomName: string
    seatCount: string
    pricePerHour: number
    amenities: string[]
    description: string
    rules: string
    images: string[]
    newImages: File[]
  }
}

export const meetingRoomsSlice = createSlice({
  name: "meetingRoom",
  initialState: meetingRoomsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchMeetingRooms: (state, action: fetchMeetingRoomsPayloadType) => {
      state.meetingRooms = []
      state.isMeetingRoomLoading = true
    },
    fetchMeetingRoomsSuccess: (
      state,
      { payload }: { payload: { meetingRooms: [] } },
    ) => {
      state.meetingRooms = payload.meetingRooms
      state.isMeetingRoomLoading = false
    },
    fetchMeetingRoomsFailed: (state) => {
      state.isMeetingRoomLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addMeetingRoom: (state, action: addMeetingRoomPayloadType) => {
      state.isMeetingRoomLoading = true
    },
    addMeetingRoomSuccess: (state) => {
      state.isMeetingRoomLoading = false
    },
    addMeetingRoomFailed: (state) => {
      state.isMeetingRoomLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateMeetingRoom: (state, action: updateMeetingRoomPayloadType) => {
      state.isMeetingRoomLoading = true
    },
    updateMeetingRoomSuccess: (state) => {
      state.isMeetingRoomLoading = false
    },
    updateMeetingRoomFailed: (state) => {
      state.isMeetingRoomLoading = false
    },

    updateSelectedCenterId: (
      state,
      { payload }: { payload: { centerId: string } },
    ) => {
      state.centerId = payload.centerId
    },
  },
})

export const {
  fetchMeetingRooms,
  fetchMeetingRoomsFailed,
  fetchMeetingRoomsSuccess,
  addMeetingRoom,
  addMeetingRoomSuccess,
  addMeetingRoomFailed,
  updateMeetingRoom,
  updateMeetingRoomSuccess,
  updateMeetingRoomFailed,
  updateSelectedCenterId,
} = meetingRoomsSlice.actions

export const selectMeetingRooms = (state: RootState) => state.meetingRooms

export default meetingRoomsSlice.reducer
