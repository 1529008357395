import axiosConfig from "../app/axiosConfig"
import {
  INITIATE_PROCESS_SCHECULE_API,
  INVOICE_DETAILS_API,
  INVOICE_ENQUEUE,
  PROCESS_SCHECULE_API,
  TRANSACTION_BANKS_API,
  TRANSACTION_DOWNLOAD_API,
  TRANSACTION_LIST_API,
  TRANSACTION_PAYMENT_METHODS_API,
  TRANSACTION_UPLOAD_API,
} from "../constants/apis"
import {
  getTransactionListPayloadType,
  uploadCSVFilePayloadType,
} from "../slices/transactionsSlice"
import { filterService } from "../utils/transactionHelpers"

export const fetchTransactionListService = ({
  payload,
}: getTransactionListPayloadType) => {
  const parameters = filterService({ payload })
  return axiosConfig.get(TRANSACTION_LIST_API, { params: parameters })
}

export const uploadCSVFileService = ({ payload }: uploadCSVFilePayloadType) => {
  const formData = new FormData()
  formData.append(`file`, payload.file)
  formData.append(`bank_account_id`, payload.accountId)
  return axiosConfig.post(TRANSACTION_UPLOAD_API, formData)
}

export const downloadCSVFileService = ({
  payload,
}: getTransactionListPayloadType) => {
  const parameters = filterService({ payload })
  return axiosConfig.get(TRANSACTION_DOWNLOAD_API, {
    responseType: "blob",
    params: parameters,
  })
}

export const getBankListService = () => axiosConfig.get(TRANSACTION_BANKS_API)

export const getPaymentMethodsService = () =>
  axiosConfig.get(TRANSACTION_PAYMENT_METHODS_API)

export const getInvoiceDetailsService = ({
  payload,
}: {
  payload: { invoice_number: string }
}) => {
  return axiosConfig.get(INVOICE_DETAILS_API, {
    params: payload,
  })
}

export const postReconciledDataService = ({ payload }: any) => {
  return axiosConfig.post(INVOICE_ENQUEUE, payload)
}

export const fetchProcessScheduleData = () => {
  return axiosConfig.get(PROCESS_SCHECULE_API)
}

export const intiateProcessSchedule = () => {
  return axiosConfig.post(INITIATE_PROCESS_SCHECULE_API)
}
