import { useEffect } from "react"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import PageHeader from "../../components/PageHeader/PageHeader"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  fetchMeetingRooms,
  selectMeetingRooms,
  updateSelectedCenterId,
} from "../../slices/meetingRoomsSlice"
import { selectCenters } from "../../slices/centersSlice"
import { MEETING_ROOMS } from "../../constants/path"
import { useNavigate } from "react-router-dom"
import { CenterDropDownWrapper, ListItemCard, ListWrapper } from "./styles"
import { Skeleton, Stack } from "@mui/material"
import { selectLogin } from "../../slices/loginSlice"
import { UserRole } from "../../constants/data"

const loadingCardsArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const MeetingRooms = () => {
  // meeting rooms store data and typed dispatch function
  const meetingRoomData = useAppSelector(selectMeetingRooms)
  const { centers, isCenterLoading } = useAppSelector(selectCenters)
  const { centerId } = useAppSelector(selectMeetingRooms)
  const { role } = useAppSelector(selectLogin)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // get meeting rooms list data when dependencies are ready
  useEffect(() => {
    if (centerId === "") {
      dispatch(
        updateSelectedCenterId({ centerId: centers[0]?.id?.toString() || "" }),
      )
    } else dispatch(fetchMeetingRooms({ centerId }))
  }, [centerId, centers, dispatch])

  return (
    <Box>
      <PageHeader title="Meeting Rooms" />
      <CenterDropDownWrapper>
        <Autocomplete
          id="center"
          options={centers}
          getOptionLabel={(option) => option.name}
          value={centers.find((center) => center.id === centerId) || null}
          onChange={(e, newValue) => {
            if (newValue) {
              dispatch(
                updateSelectedCenterId({ centerId: newValue.id.toString() }),
              )
            } else {
              dispatch(updateSelectedCenterId({ centerId: "" }))
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Center"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          )}
          disabled={isCenterLoading}
        />
      </CenterDropDownWrapper>
      {meetingRoomData.isMeetingRoomLoading && (
        <ListWrapper>
          {loadingCardsArr.map((item) => (
            <ListItemCard
              key={item}
              style={{ cursor: "auto", boxShadow: "none", transform: "none" }}
            >
              <Stack gap={2}>
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
              </Stack>
            </ListItemCard>
          ))}
        </ListWrapper>
      )}
      <ListWrapper>
        {role === UserRole.ADMIN && !meetingRoomData.isMeetingRoomLoading && (
          <ListItemCard
            onClick={() =>
              navigate(`${MEETING_ROOMS}/add`, { state: { centerId } })
            }
          >
            Add New
          </ListItemCard>
        )}
        {meetingRoomData.meetingRooms.map((meetingRoom) => (
          <ListItemCard
            key={meetingRoom.id}
            onClick={() =>
              navigate(`${MEETING_ROOMS}/update`, {
                state: { meetingRoom, centerId },
              })
            }
          >
            {meetingRoom.name}
          </ListItemCard>
        ))}
      </ListWrapper>
    </Box>
  )
}

export default MeetingRooms
