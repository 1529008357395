import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { debounce } from "lodash"
import { useCallback } from "react"
import { useParams } from "react-router-dom"

interface CommonAutocompleteProps<T> {
  options: T[]
  getOptionLabel: (option: T) => string
  value: T | T[] | null
  onChange: (newValue: T | T[] | null) => void
  placeholder: string
  label: string
  multiple?: boolean
  fetchOptionsDebounced: (inputValue: string) => void
}

function CommonAutocomplete<T>(props: CommonAutocompleteProps<T>) {
  const {
    options,
    getOptionLabel,
    value,
    onChange,
    placeholder,
    label,
    multiple,
    fetchOptionsDebounced,
  } = props

  const { userId } = useParams()

  const uniqueOptions: T[] = options.filter(
    (option, index, self) =>
      self.findIndex(
        (other) => getOptionLabel(other) === getOptionLabel(option),
      ) === index,
  )

  const debouncedOnChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === "") {
        fetchOptionsDebounced("a")
        onChange(null)
      }
      fetchOptionsDebounced(event.target.value)
    },
    300,
  )

  const handleDebouncedOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedOnChange(event)
    },
    [debouncedOnChange],
  )

  return (
    <Autocomplete
      style={{
        width: "100%",
      }}
      options={uniqueOptions}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      multiple={multiple}
      disabled={!!userId}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          size="small"
          onChange={handleDebouncedOnChange}
        />
      )}
    />
  )
}

export default CommonAutocomplete
