import { Card, CardProps, Stack, styled, Tab } from "@mui/material"

interface StyledCardProps extends CardProps {
  isSmallScreen?: boolean
}

export const KYCPlaceholder = styled(Stack)`
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 10px;
  justify-content: space-around;
`

export const StyledTab = styled(Tab)`
  && {
    background-color: "#fff";
  }
`

export const StyledCard = styled(Card)<StyledCardProps>`
  && {
    width: ${({ isSmallScreen }) => (isSmallScreen ? "90%" : "80%")};
    background-color: #f4f7fa;
    box-shadow: none;
    margin-top: 20px;
    justify-items: center;
  }
`
