import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { centerType } from "./centersSlice"

type DaypassBookingState = {
  daypassEnabledCenters: centerType[]
  isLoading: boolean
}

export const daypassBookingInitialState: DaypassBookingState = {
  daypassEnabledCenters: [],
  isLoading: false,
}

export type bookDaypassPayloadType = {
  payload: {
    startDate: string | undefined
    numberOfDays: number
    center: string
    name: string
    email: string
    mobileNo: string
    bookingRef: string
  }
}

export const daypassBookingSlice = createSlice({
  name: "daypassBooking",
  initialState: daypassBookingInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bookDaypass: (state, action: bookDaypassPayloadType) => {
      state.isLoading = true
    },
    bookDaypassSuccess: (state) => {
      state.isLoading = false
    },
    bookDaypassFailed: (state) => {
      state.isLoading = false
    },

    fetchDaypassEnabledCenters: (state) => {
      state.daypassEnabledCenters = []
      state.isLoading = true
    },
    fetchDaypassEnabledCentersSuccess: (
      state,
      { payload }: { payload: { centers: [] } },
    ) => {
      state.daypassEnabledCenters = payload.centers
      state.isLoading = false
    },
    fetchDaypassEnabledCentersFailed: (state) => {
      state.isLoading = false
    },
  },
})

export const {
  bookDaypass,
  bookDaypassSuccess,
  bookDaypassFailed,
  fetchDaypassEnabledCenters,
  fetchDaypassEnabledCentersFailed,
  fetchDaypassEnabledCentersSuccess,
} = daypassBookingSlice.actions

export const selectDaypassBooking = (state: RootState) => state.daypassBooking

export default daypassBookingSlice.reducer
