import React, { useState } from "react"
import {
  FormControl,
  TextField,
  Tooltip,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Stack,
  SelectChangeEvent,
} from "@mui/material"
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import { UserRole, UserStatus } from "../../constants/data"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectLogin } from "../../slices/loginSlice"
import {
  resetFilter,
  selectUsers,
  setFilter,
  setPage,
} from "../../slices/usersSlice"

interface UserFilterProps {
  handleRefresh: () => void
  roles: Role[]
  isLargeScreen: boolean
  isSmallScreen: boolean
  isMediumScreen: boolean
  setOpen: React.Dispatch<React.SetStateAction<any>>
}

interface Role {
  id: string
  name: string
}

const UserFilter: React.FC<UserFilterProps> = ({
  handleRefresh,
  roles,
  isSmallScreen,
  isMediumScreen,
  setOpen,
}) => {
  const dispatch = useAppDispatch()
  const [isEmailValid, setIsEmailValid] = useState(true)
  const { role } = useAppSelector(selectLogin)
  const { filters } = useAppSelector(selectUsers)

  //handle status change
  const handleStatusChange = (
    event: SelectChangeEvent<typeof filters.status>,
  ) => {
    const {
      target: { value },
    } = event
    dispatch(
      setFilter({
        ...filters,
        status: typeof value === "string" ? value.split(",") : value,
      }),
    )
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === "email") {
      // Check if the email is valid
      const isValid = /^\S+@\S+\.\S+$/.test(value)
      setIsEmailValid(isValid)
      setTimeout(() => {
        setIsEmailValid(true)
      }, 3000)
    }

    dispatch(setFilter({ ...filters, [name]: value ?? undefined }))
  }

  const resetFilterHandler = () => {
    dispatch(resetFilter())
    dispatch(setPage(0))
  }

  return (
    <Box style={{ width: "100%" }}>
      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 2,
          gridTemplateColumns: isSmallScreen
            ? "1fr"
            : isMediumScreen
            ? "repeat(3, 9fr)"
            : "repeat(5, 9fr)",
          width: "100%",
        }}
      >
        <FormControl>
          <TextField
            size="small"
            variant="outlined"
            label="Name"
            name="firstName"
            value={filters.firstName}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            label="Email"
            name="email"
            type="email"
            size="small"
            value={filters.email}
            onChange={handleInputChange}
            error={!isEmailValid}
            helperText={isEmailValid ? "" : "Invalid email"}
          />
        </FormControl>
        <FormControl>
          <TextField
            label="Phone No"
            name="mobile"
            type="tel"
            size="small"
            value={filters.mobile}
            onChange={handleInputChange}
            error={filters.mobile.length !== 10 && filters.mobile.length > 0}
            helperText={
              filters.mobile.length !== 10 &&
              filters.mobile.length > 0 &&
              "Enter 10 digits Phone number"
            }
          />
        </FormControl>
        <FormControl size="small">
          <InputLabel id="status-type">Status</InputLabel>
          <Select
            labelId="status-type"
            id="status"
            value={filters.status || ""}
            label="Status"
            onChange={handleStatusChange}
            multiple
          >
            <MenuItem value={[]}>All</MenuItem>
            {Object.values(UserStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {status.charAt(0) + status.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel id="role-type">Role</InputLabel>
          <Select
            labelId="role-type"
            id="role"
            value={filters.role || ""}
            label="Role"
            onChange={(event) => {
              const newValue = event.target.value
              dispatch(
                setFilter({
                  ...filters,
                  role: newValue,
                }),
              )
            }}
          >
            <MenuItem value={[]}>All</MenuItem>
            {roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 1,
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          gap={2}
          marginTop={"10px"}
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          style={{ gridColumn: isSmallScreen ? 1 : 1 }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              width: isSmallScreen ? "50%" : "120px",
              height: "36px",
              mx: isSmallScreen ? "auto" : "0",
            }}
            onClick={() => setOpen(true)}
            disabled={role !== UserRole.ADMIN}
          >
            Invite user
          </Button>
        </Stack>

        <Stack
          direction="row"
          gap={2}
          marginTop={"10px"}
          alignItems="center"
          justifyContent={isSmallScreen ? "center" : "flex-end"}
          style={{ gridColumn: isSmallScreen ? 1 : 5 }}
        >
          <Tooltip title="Refresh">
            <Button
              variant="outlined"
              startIcon={<RefreshRoundedIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
          </Tooltip>
          <Tooltip title="Reset Filter" arrow>
            <Button
              variant="outlined"
              startIcon={<CachedOutlinedIcon color="primary" />}
              onClick={resetFilterHandler}
            >
              Reset
            </Button>
          </Tooltip>
        </Stack>
      </Box>
    </Box>
  )
}

export default UserFilter
