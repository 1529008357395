import React from "react"
import {
  Stack,
  Tooltip,
  Button,
  CircularProgress,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@mui/material"
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  downloadCSVFile,
  initiateProcessSchedule,
  selectTransactions,
  setFilters,
} from "../../slices/transactionsSlice"
import {
  TransactionType,
  MarkedStatus,
  UserRole,
  operators,
  allowedOnlyBankAccountsRoles,
} from "../../constants/data"
import DateRangeSelector from "../../components/DateRangeSelector/DateRangeSelector"
import { Dayjs } from "dayjs"
import { selectLogin } from "../../slices/loginSlice"
import { constructApiRequestParams } from "../../utils/transactionHelpers"
interface TransactionsFiltersProps {
  isSmallScreen: boolean
  isMediumScreen: boolean
  handleRefresh: () => void
  resetFilterHandler: () => void
  handleUploadDialogOpen: () => void
}

const TransactionsFilters: React.FC<TransactionsFiltersProps> = ({
  isSmallScreen,
  isMediumScreen,
  handleRefresh,
  resetFilterHandler,
  handleUploadDialogOpen,
}) => {
  const {
    filters,
    isBankListLoading,
    isUploading,
    bankList,
    paymentMethods,
    isPaymentMethodsLoading,
    isDownloading,
    pagination,
    processScheduleData,
    isProcessScheduleLoading,
  } = useAppSelector(selectTransactions)
  const dispatch = useAppDispatch()
  const { role } = useAppSelector(selectLogin)

  const handleDateRangeSelection = (newState: {
    dateSelector: string
    startDate?: Dayjs | null
    endDate?: Dayjs | null
  }) => {
    dispatch(
      setFilters({
        ...filters,
        ...newState,
      }),
    )
  }

  const options = [{ accountName: "All", id: "all" }, ...bankList]
  const handleFileDownload = () => {
    dispatch(downloadCSVFile(constructApiRequestParams(filters, pagination)))
  }
  const handleProcessSchedule = () => {
    dispatch(initiateProcessSchedule())
  }

  return (
    <Box
      style={{
        padding: 20,
        marginBottom: "10px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 2,
          gridTemplateColumns: isSmallScreen
            ? "1fr"
            : isMediumScreen
            ? "repeat(3, 9fr)"
            : "repeat(5, 9fr)",
          width: "100%",
        }}
      >
        <FormControl size="small">
          <TextField
            size="small"
            variant="outlined"
            label="Search"
            placeholder="Search by Description & Reference No."
            value={filters.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setFilters({ ...filters, search: e.target.value }))
            }}
          />
        </FormControl>

        <FormControl size="small">
          <InputLabel id="select-opertator">Operator</InputLabel>
          <Select
            labelId="select-opertator"
            id="selectOperator"
            label="Operator"
            value={filters.operator}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  operator: e.target.value as string,
                }),
              )
            }
            disabled={filters.search === ""}
          >
            {operators.map((operator) => (
              <MenuItem value={operator?.value}>{operator?.option}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="select-accounts">Account</InputLabel>
          <Select
            labelId="select-accounts"
            id="selectAccounts"
            label="Account"
            value={filters.accountId}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  accountId: e.target.value as string,
                }),
              )
            }
            disabled={isBankListLoading}
          >
            {isBankListLoading ? (
              <MenuItem value="">Loading...</MenuItem>
            ) : (
              options.map((bank) => (
                <MenuItem key={bank.id} value={bank.id}>
                  {bank.accountName}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="select-transaction-type">Transaction Type</InputLabel>
          <Select
            labelId="select-transaction-type"
            id="selectTransactionType"
            label="Transaction Type"
            value={filters.transactionType}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  transactionType: e.target.value as string,
                }),
              )
            }
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value={TransactionType.DEBIT}>Debit</MenuItem>
            <MenuItem value={TransactionType.CREDIT}>Credit</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="marked-status-select">Marked Status</InputLabel>
          <Select
            labelId="marked-status-select"
            id="isMarked"
            value={filters.isMarked}
            label="Marked Status"
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  isMarked: e.target.value as string,
                }),
              )
            }
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value={MarkedStatus.MARKED}>Marked</MenuItem>
            <MenuItem value={MarkedStatus.UNMARKED}>Unmarked</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="sort-by-select">Sort By</InputLabel>
          <Select
            labelId="sort-by-select"
            id="sortBySelect"
            value={filters.sortBy}
            label="Sort By"
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({ ...filters, sortBy: e.target.value as string }),
              )
            }
          >
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="amount">Amount</MenuItem>
            <MenuItem value="none">None</MenuItem>
          </Select>
        </FormControl>

        {filters.sortBy !== "none" && (
          <FormControl
            style={{
              width: "100%",
              alignSelf: isSmallScreen ? "center" : "end",
            }}
            size="small"
          >
            <InputLabel id="order-by-select">Order By</InputLabel>
            <Select
              labelId="order-by-select"
              id="orderBySelect"
              value={filters.orderBy}
              label="Order By"
              onChange={(e: SelectChangeEvent) =>
                dispatch(
                  setFilters({
                    ...filters,
                    orderBy: e.target.value as string,
                  }),
                )
              }
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>
        )}

        <FormControl size="small">
          <InputLabel id="payment-method-select">Payment Method</InputLabel>
          <Select
            labelId="payment-method-select"
            id="paymentMethodSelect"
            value={filters.paymentMethod}
            label="Payment Method"
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  paymentMethod: e.target.value as string,
                }),
              )
            }
            disabled={isPaymentMethodsLoading}
          >
            <MenuItem value="all">All</MenuItem>
            {paymentMethods.map((method) => (
              <MenuItem value={method.key}>{method.value}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <DateRangeSelector
          dateSelector={filters.dateSelector}
          filterState={filters}
          updateFilterState={handleDateRangeSelection}
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 1,
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          gap={2}
          marginTop={"10px"}
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          style={{ gridColumn: isSmallScreen ? 1 : 1 }}
        >
          <Button
            variant="contained"
            disabled={
              isUploading ||
              role === UserRole.ACCOUNT ||
              role === UserRole.ALTS_SALES
            }
            onClick={handleUploadDialogOpen}
            sx={{
              width: isSmallScreen ? "50%" : "150px",
              height: "36px",
              mx: isSmallScreen ? "auto" : "0",
            }}
          >
            {isUploading ? (
              <CircularProgress size={26} color="primary" />
            ) : (
              "Upload"
            )}
          </Button>
          {false ?? (
            <Button
              variant="outlined"
              disabled={
                isDownloading ||
                role === UserRole.ACCOUNT ||
                role === UserRole.ALTS_SALES
              }
              onClick={handleFileDownload}
              sx={{
                width: isSmallScreen ? "50%" : "150px",
                height: "36px",
                mx: isSmallScreen ? "auto" : "0",
              }}
            >
              {isDownloading ? (
                <CircularProgress size={26} color="primary" />
              ) : (
                "Download"
              )}
            </Button>
          )}
          {allowedOnlyBankAccountsRoles.includes(role) ? (
            <Tooltip
              title={
                processScheduleData.status
                  ? processScheduleData?.message
                  : "Click to process schedule"
              }
            >
              <Button
                variant="outlined"
                disabled={
                  isProcessScheduleLoading || processScheduleData?.status
                }
                onClick={handleProcessSchedule}
                sx={{
                  width: isSmallScreen ? "50%" : "fit-content",
                  height: "36px",
                  mx: isSmallScreen ? "auto" : "0",
                }}
              >
                {isProcessScheduleLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : processScheduleData.status ? (
                  `Next reconcilation scheduled at ${processScheduleData.nextRun}`
                ) : (
                  "SCHEDULE RECONCILIATION"
                )}
              </Button>
            </Tooltip>
          ) : null}
        </Stack>
        <Stack
          direction="row"
          gap={2}
          marginTop={"10px"}
          alignItems="center"
          justifyContent={isSmallScreen ? "center" : "flex-end"}
          style={{ gridColumn: isSmallScreen ? 1 : 5 }}
        >
          <Tooltip title="Refresh">
            <Button
              variant="outlined"
              startIcon={<RefreshRoundedIcon />}
              onClick={handleRefresh}
              style={{ overflow: "hidden" }}
            >
              Refresh
            </Button>
          </Tooltip>
          <Tooltip title="Reset Filter" arrow>
            <Button
              variant="outlined"
              startIcon={<CachedOutlinedIcon />}
              onClick={resetFilterHandler}
              style={{
                overflow: "hidden",
              }}
            >
              Reset
            </Button>
          </Tooltip>
        </Stack>
      </Box>
    </Box>
  )
}

export default TransactionsFilters
