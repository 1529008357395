import axiosConfig from "../app/axiosConfig"
import {
  BOOKINGS_API,
  BOOKINGS_MEETING_ROOMS_API,
  USER_DETAILS_BY_ID_API,
} from "../constants/apis"
import {
  fetchBookingDetailsPayloadType,
  fetchBookingListPayloadType,
} from "../slices/bookingHistorySlice"
import { computeRoute } from "../utils/compute-route"

export const fetchDaypassBookingService = ({
  payload,
}: fetchBookingListPayloadType) =>
  axiosConfig.get(BOOKINGS_API, {
    params: {
      start_date: payload.startDate,
      end_date: payload.endDate,
      limit: payload.pageSize,
      page: payload.pageNo,
      type: payload.type,
      center_id: payload.center,
      is_active: payload.isActive,
      payment_status: payload.paymentStatus,
      booking_ref: payload.bookingRef,
      user_id: payload.userId,
    },
  })

export const fetchMeetingRoomBookingService = () =>
  axiosConfig.get(BOOKINGS_MEETING_ROOMS_API)

export const fetchBookingDetailsService = ({
  payload,
}: fetchBookingDetailsPayloadType) =>
  axiosConfig.get(computeRoute(USER_DETAILS_BY_ID_API, { ...payload }))
