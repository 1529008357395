import { takeLatest, put, call, select } from "redux-saga/effects"
import {} from "../services/bookingHistoryService"
import {
  fetchCenterLogs,
  fetchCenterLogsSuccess,
  fetchCenterLogsFailed,
  fetchCenterLogsPayloadType,
  CenterLogType,
} from "../slices/auditLogsSlice"
import { AxiosResponse } from "axios"
import { fetchCenterLogsService } from "../services/auditLogsService"
import { centerType, selectCenters } from "../slices/centersSlice"

export function* fetchCenterLogsSaga(action: fetchCenterLogsPayloadType) {
  try {
    const { data }: AxiosResponse = yield call(fetchCenterLogsService, action)
    const { centers } = yield select(selectCenters)

    yield put(
      fetchCenterLogsSuccess({
        centerLogs:
          data?.data.map((log: CenterLogType) => ({
            ...log,
            centerName: centers.find(
              (center: centerType) => center.id === log.center_id,
            )?.name,
          })) || [],
        totalRowCount: data.pagination.total || 0,
      }),
    )
  } catch (err) {
    yield put(fetchCenterLogsFailed())
  }
}

export default function* auditLogsSaga() {
  yield takeLatest(fetchCenterLogs, fetchCenterLogsSaga)
}
