import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { VerificationLogStatus } from "../constants/data"
import dayjs, { Dayjs } from "dayjs"

export type CenterLogType = {
  id: string
  user: {
    id: string
    first_name: string
    last_name: string
  }
  center_id: string
  purpose: string
  status: string
  scanned_at: string
  type: string
  centerName: string
  day_pass_booking_details: {
    booking_code: string
    daypass_type: string
    booking_date: string
  }
}

type FilterType = {
  dateSelector: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  centerId?: string | null
  status?: string[]
  userId?: string
  gatekeeper?: string
}

export type PaginationType = {
  page: number
  pageSize: number
}

type AuditLogsState = {
  centerLogs: CenterLogType[]
  isCenterLogsLoading: boolean
  pageInfo: {
    totalRowCount: number
  }
  pagination: PaginationType
  filters: FilterType
}

const initialFilterState = {
  dateSelector: "month",
  startDate: dayjs().startOf("month"),
  endDate: dayjs(),
  centerId: "all",
  userId: "",
  gatekeeper: "",
  status: [VerificationLogStatus.ACCEPT, VerificationLogStatus.FAILED],
}

const initialPaginationState = {
  page: 0,
  pageSize: 10,
}

export const auditLogsInitialState: AuditLogsState = {
  centerLogs: [],
  isCenterLogsLoading: false,
  pageInfo: {
    totalRowCount: 0,
  },
  pagination: initialPaginationState,
  filters: initialFilterState,
}

export type fetchCenterLogsPayloadType = {
  payload: {
    centerId?: string | null
    page: number
    pageSize: number
    status?: string[]
    startDate?: string
    endDate?: string
    userId: string
    gatekeeper?: string
  }
}

export const auditLogsSlice = createSlice({
  name: "auditLogs",
  initialState: auditLogsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchCenterLogs: (state, action: fetchCenterLogsPayloadType) => {
      state.isCenterLogsLoading = true
    },
    fetchCenterLogsSuccess: (
      state,
      {
        payload,
      }: { payload: { centerLogs: CenterLogType[]; totalRowCount: number } },
    ) => {
      state.centerLogs = payload.centerLogs
      state.isCenterLogsLoading = false
      state.pageInfo.totalRowCount = payload.totalRowCount
    },
    fetchCenterLogsFailed: (state) => {
      state.isCenterLogsLoading = false
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    resetFilters: (state) => {
      state.filters = initialFilterState
      state.pagination = initialPaginationState
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload
    },
  },
})

export const {
  fetchCenterLogs,
  fetchCenterLogsSuccess,
  fetchCenterLogsFailed,
  setFilters,
  resetFilters,
  setPagination,
} = auditLogsSlice.actions

export const selectAuditLogs = (state: RootState) => state.auditLogs

export default auditLogsSlice.reducer
