import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import { CircularProgress, Stack, TextField } from "@mui/material"
import { selectLogin, login } from "../../slices/loginSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { BOOKING_HISTORY, TRANSACTIONS } from "../../constants/path"
import BhiveLogo from "../../assets/Bhive.png"
import { Logo, SignInButton, Wrapper } from "./styles"
import { allowedOnlyTrasactionsRoles } from "../../constants/data"

export function Login() {
  const { token, role, loading } = useAppSelector(selectLogin)
  const dispatch = useAppDispatch()

  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  })

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target
    const { value } = event.target
    setInputData({ ...inputData, [name]: value })
  }

  const onLoginHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch(login({ email: inputData.email, password: inputData.password }))
  }

  return (
    <>
      {token ? (
        <Navigate
          to={
            allowedOnlyTrasactionsRoles.includes(role)
              ? TRANSACTIONS
              : BOOKING_HISTORY
          }
        />
      ) : (
        <Wrapper>
          <Logo className="title" src={BhiveLogo} alt="logo" />
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Email"
              id="email"
              name="email"
              value={inputData.email}
              onChange={onChangeHandler}
            />
            <TextField
              fullWidth
              type="password"
              label="Password"
              id="password"
              name="password"
              autoComplete="current-password"
              value={inputData.password}
              onChange={onChangeHandler}
              onKeyDown={(event) => {
                if (event.key === "Enter" && inputData.password.trim() !== "") {
                  dispatch(
                    login({
                      email: inputData.email,
                      password: inputData.password,
                    }),
                  )
                }
              }}
            />
            {loading ? (
              <SignInButton variant="contained" disabled>
                <CircularProgress size={22} color="primary" />
              </SignInButton>
            ) : (
              <SignInButton
                variant="contained"
                onClick={onLoginHandler}
                disabled={
                  inputData.email.trim() === "" ||
                  inputData.password.trim() === ""
                }
              >
                Sign in to your account
              </SignInButton>
            )}
          </Stack>
        </Wrapper>
      )}
    </>
  )
}

export default Login
