import {
  Button,
  Typography,
  Stack,
  Box,
  Dialog,
  DialogContent,
  Tooltip,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import ElevatedCard from "../../components/ElevatedCard/ElevatedCard"
import { InvoiceEnqueue, Transaction } from "../../models/transactions.model"
import { Field, FieldArray, Form, Formik } from "formik"
import {
  getInvoiceDetails,
  getProcessSchedule,
  getTransactionList,
  postReconciledData,
  reconcileType,
  resetInvoiceDetails,
  selectTransactions,
  setReconcile,
} from "../../slices/transactionsSlice"
import * as yup from "yup"
import InvoiceInputField from "../../components/InvoiceFields/InvoiceInputField"
import FindInPageIcon from "@mui/icons-material/FindInPage"
import { useEffect } from "react"
import SwitchField from "../../components/InvoiceFields/SwitchField"
import {
  constructApiRequestParams,
  hasDuplicateInvoiceNumber,
  titleCase,
} from "../../utils/transactionHelpers"
import { showToast } from "../../slices/toastSlice"
import { selectLogin } from "../../slices/loginSlice"
import { allowedOnlyBankAccountsRoles } from "../../constants/data"

type reconcileDetailProps = {
  open: boolean
  onClose: () => void
  transactionData: Transaction
}

const ReconcileForm = ({
  open,
  onClose,
  transactionData,
}: reconcileDetailProps) => {
  const { invoiceNumber, id, invoiceSource } = transactionData
  const {
    invoiceDetails,
    reconcile,
    postReconcileSuccess,
    isPostReconcileDataLoading,
    filters,
    pagination,
  } = useAppSelector(selectTransactions)
  const dispatch = useAppDispatch()
  const { role } = useAppSelector(selectLogin)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const invoiceId = transactionData?.invoiceId ?? invoiceDetails?.invoiceId
  const sourceCheck = invoiceDetails.source === invoiceSource
  const sourceErrorMsg = `Invoice (${titleCase(
    invoiceDetails.source,
  )}) and transaction (${titleCase(invoiceSource)}) sources don't match`

  const validationSchema = yup.object().shape({
    reconcile: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup.number().min(0, "Amount must be greater than 0"),
          invoiceNumber: yup
            .string()
            .required("Enter invoice number and click button to proceed")
            .test(
              "invoiceNumber",
              invoiceDetails.source ? sourceErrorMsg : "",
              () => {
                return sourceCheck
              },
            ),
          tdsDeducted: yup.boolean(),
          tdsAmountWithHeld: yup
            .number()
            .min(0, "TDS Amount with held must be greater than 0"),
        }),
      )
      .test(
        "invoiceNumber",
        "Duplicate invoice numbers are not allowed",
        (reconcileData) => {
          return !hasDuplicateInvoiceNumber(reconcileData)
        },
      )
      .test(
        "total-amount",
        `Total amount must not exceed ${transactionData?.amount}`,
        (reconcileData) => {
          const totalAmount = reconcileData?.reduce(
            (sum, item) => sum + (item.amount || 0),
            0,
          )
          return (totalAmount ?? 0) <= transactionData?.amount
        },
      ),
  })

  const handleClose = () => {
    onClose(), dispatch(resetInvoiceDetails())
  }

  const handleSubmit = (values: { reconcile: reconcileType }) => {
    const reconcileMappedData = values.reconcile.map((reconcileObj) => {
      return { ...new InvoiceEnqueue(reconcileObj) }
    })

    dispatch(
      postReconciledData({
        transaction_id: id,
        reconcile: reconcileMappedData,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      setReconcile({
        reconcile: [
          {
            amount: transactionData?.amount,
            invoiceNumber: invoiceNumber,
            tdsDeducted: false,
            tdsAmountWithHeld: 0,
            tdsPercentage: 10,
            invoiceId: invoiceId,
          },
        ],
      }),
    )
    return () => {
      dispatch(dispatch(resetInvoiceDetails()))
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (postReconcileSuccess) {
      handleClose()
      dispatch(
        getTransactionList(constructApiRequestParams(filters, pagination)),
      )
      if (allowedOnlyBankAccountsRoles.includes(role)) {
        dispatch(getProcessSchedule())
      }
    }
  }, [postReconcileSuccess]) // eslint-disable-line

  useEffect(() => {
    if (invoiceDetails.source) {
      if (invoiceSource !== invoiceDetails.source) {
        dispatch(
          showToast({
            toastType: "error",
            msg: sourceErrorMsg,
          }),
        )
      }
    }
  }, [invoiceSource, invoiceDetails.source, dispatch, sourceErrorMsg])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography variant="h6">{"Reconcile Manually"}</Typography>
        <Box>
          <Formik
            initialValues={{
              reconcile,
            }}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({ values, handleChange, errors, setFieldValue }) => {
              return (
                <Form>
                  <FieldArray name="reconcile">
                    {({ remove, push }) =>
                      values.reconcile.map((_, index) => {
                        const { reconcile } = values
                        const {
                          amount,
                          invoiceNumber,
                          tdsDeducted,
                          tdsAmountWithHeld,
                          tdsPercentage,
                          invoiceId,
                        } = reconcile[index]
                        const tdsChecks =
                          invoiceId === "" || !tdsDeducted || !sourceCheck
                        const invoiceIdChecks = !invoiceId || !sourceCheck
                        return (
                          <ElevatedCard
                            style={{ width: isSmallScreen ? "84%" : "508px" }}
                            key={index}
                          >
                            <Stack component="form" gap={2}>
                              <Stack display="flex" flexDirection="row">
                                <Field
                                  name={`reconcile.${index}.invoiceNumber`}
                                  id={`reconcile.${index}.invoiceNumber`}
                                  label="Invoice Number"
                                  value={invoiceNumber}
                                  onChange={handleChange}
                                  required
                                  size="small"
                                  sx={{ width: "100%" }}
                                  component={InvoiceInputField}
                                />
                                <Tooltip title="Check Invoice Number">
                                  <Button
                                    disabled={!invoiceNumber}
                                    onClick={() => {
                                      dispatch(
                                        getInvoiceDetails({
                                          values,
                                          index,
                                        }),
                                      )
                                    }}
                                  >
                                    <FindInPageIcon />
                                  </Button>
                                </Tooltip>
                              </Stack>
                              <SwitchField
                                label="Is TDS deducted?"
                                id={`reconcile.${index}.tdsDeducted`}
                                option={["No", "Yes"]}
                                checked={tdsDeducted}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue(
                                    `reconcile.${index}.tdsDeducted`,
                                    e.target.checked ? true : false,
                                  )
                                  if (e.target.checked === false) {
                                    setFieldValue(
                                      `reconcile.${index}.tdsAmountWithHeld`,
                                      0,
                                    )
                                  } else {
                                    setFieldValue(
                                      `reconcile.${index}.tdsAmountWithHeld`,
                                      invoiceDetails?.tds10Percent,
                                    )
                                    setFieldValue(
                                      `reconcile.${index}.tdsPercentage`,
                                      10,
                                    )
                                  }
                                }}
                                disabled={invoiceIdChecks}
                              />
                              <SwitchField
                                label="TDS Percentage :"
                                id={`reconcile.${index}.tdsPercentage`}
                                checked={tdsPercentage === 2}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue(
                                    `reconcile.${index}.tdsPercentage`,
                                    e.target.checked ? 2 : 10,
                                  )
                                  setFieldValue(
                                    `reconcile.${index}.tdsAmountWithHeld`,
                                    e.target.checked
                                      ? invoiceDetails?.tds2Percent
                                      : invoiceDetails?.tds10Percent,
                                  )
                                }}
                                option={["10%", "2%"]}
                                disabled={tdsChecks}
                              />
                              <Field
                                name={`reconcile.${index}.tdsAmountWithHeld`}
                                id={`reconcile.${index}.tdsAmountWithHeld`}
                                label="TDS Amount WithHeld"
                                type="number"
                                onChange={handleChange}
                                disabled={tdsChecks}
                                value={tdsAmountWithHeld}
                                size="small"
                                sx={{ width: "100%" }}
                                component={InvoiceInputField}
                              />
                              <Field
                                name={`reconcile.${index}.amount`}
                                id={`reconcile.${index}.amount`}
                                label="Amount"
                                type="number"
                                onChange={handleChange}
                                disabled={invoiceIdChecks}
                                value={amount}
                                required
                                size="small"
                                sx={{ width: "100%" }}
                                component={InvoiceInputField}
                              />
                              <Stack direction="row" gap={10}>
                                <Button
                                  onClick={() =>
                                    push({
                                      amount: Infinity,
                                      invoiceNumber: "",
                                      tdsDeducted: false,
                                      tdsAmountWithHeld: 0,
                                      tdsPercentage: 10,
                                      invoiceId: "",
                                    })
                                  }
                                  disabled={
                                    invoiceIdChecks ||
                                    Object.keys(errors).length > 0 ||
                                    amount > transactionData.amount
                                  }
                                  sx={{ flexGrow: 1 }}
                                  variant="contained"
                                  color="primary"
                                >
                                  Add more
                                </Button>
                                <Button
                                  sx={{ flexGrow: 1 }}
                                  onClick={() => remove(index)}
                                  variant="contained"
                                  color="secondary"
                                  disabled={!(values.reconcile.length > 1)}
                                >
                                  Remove
                                </Button>
                              </Stack>
                            </Stack>
                          </ElevatedCard>
                        )
                      })
                    }
                  </FieldArray>
                  <ElevatedCard
                    style={{ width: isSmallScreen ? "84%" : "508px" }}
                  >
                    <Stack direction="row" gap={10}>
                      <Button
                        type="submit"
                        sx={{ flexGrow: 1 }}
                        variant="contained"
                        color="primary"
                        disabled={
                          Object.keys(errors).length > 0 ||
                          isPostReconcileDataLoading
                        }
                      >
                        Submit
                      </Button>
                      <Button
                        sx={{ flexGrow: 1 }}
                        onClick={handleClose}
                        variant="contained"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </ElevatedCard>
                </Form>
              )
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ReconcileForm
