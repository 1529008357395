import { Stack } from "@mui/material"
import { useAppSelector } from "../../app/hooks"
import { selectVersionDialog } from "./slice"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

export function VersionDailog(): JSX.Element {
  const { open } = useAppSelector(selectVersionDialog)

  const handleClose = () => {
    // we dont need to hide dialog as we are reloading the app in the axios intercepter
    // which will reset the dialog state to its initial value
    localStorage.clear()
    window.location.reload()
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="update-dialog-title"
      aria-describedby="update-dialog-description"
    >
      <DialogTitle id="update-dialog-title">
        A new version of the App is available <br />
        Please update to continue
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Button onClick={handleClose} variant="outlined" size="small">
            Update
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default VersionDailog
