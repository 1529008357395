import styled from "@emotion/styled"
import { TextField } from "@mui/material"
import { FieldProps, getIn } from "formik"

const Error = styled.div`
  color: orangered;
  margin-top: 0.4rem;
  font-size: 0.8rem;
`

const InvoiceInputFieldWrapper = styled.div`
  width: 100%;
`

const InvoiceInputField = ({
  field,
  form: { errors },
  ...props
}: FieldProps) => {
  const errorMessage = getIn(errors, field.name)
  const amountErrorMessage = errors.reconcile
  const amountErrorChecks =
    field.name.split(".")[2] === "amount" &&
    typeof errors.reconcile === "string"

  return (
    <InvoiceInputFieldWrapper>
      <TextField {...props} {...field} />
      {amountErrorChecks && <Error>{`${amountErrorMessage}`}</Error>}
      {errorMessage && <Error>{errorMessage}</Error>}
    </InvoiceInputFieldWrapper>
  )
}

export default InvoiceInputField
