import styled from "@emotion/styled"
import { Button, Divider as MUIDivider } from "@mui/material"

// Media Query for small screens (e.g., mobile)
const smallScreen = "@media (max-width: 768px)"

export const Wrapper = styled.div`
  width: 350px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background: #ffffff;
  padding: 50px 42px 100px;

  ${smallScreen} {
    width: 330px;
    padding: 50px 10px;
  }
`
export const Logo = styled.img`
  height: 60px;
  margin-bottom: 40px;
`
export const SignInButton = styled(Button)`
  margin-top: 32px;
  height: 52px;
`
export const GoogleButton = styled(Button)`
  width: inherit;
  height: 52px;
`
export const Divider = styled(MUIDivider)`
  margin: 20px 0px;
  font-size: 12px;
  color: dimgrey;
`
