import axiosConfig from "../app/axiosConfig"
import { BOOKINGS_DAYPASS_API, CENTERS_API } from "../constants/apis"
import { bookDaypassPayloadType } from "../slices/daypassBookingSlice"

export const bookDaypassService = ({ payload }: bookDaypassPayloadType) =>
  axiosConfig.post(BOOKINGS_DAYPASS_API, {
    user_details: {
      first_name: payload.name.split(" ")[0],
      last_name: payload.name.split(" ")[1] || "",
      email: payload.email,
      mobile: `${payload.mobileNo}`,
    },
    day_pass_booking: {
      center_id: payload.center,
      booking_date: payload.startDate,
      pass_count: payload.numberOfDays,
      booking_ref: payload.bookingRef,
    },
  })

export const fetchDaypassEnabledCentersService = () =>
  axiosConfig.get(CENTERS_API, {
    params: {
      limit: 1000,
      is_day_pass_enabled: true,
    },
  })
