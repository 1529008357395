import { Button, Typography, styled } from "@mui/material"
import ElevatedCard from "../ElevatedCard/ElevatedCard"
import { useNavigate } from "react-router-dom"
import { ROOT, TRANSACTIONS } from "../../constants/path"
import { selectLogin } from "../../slices/loginSlice"
import { useAppSelector } from "../../app/hooks"
import { UserRole } from "../../constants/data"

// Media Query for small screens (e.g., mobile)
const smallScreen = "@media (max-width: 768px)"

export const Wrapper = styled(ElevatedCard)`
  margin-top: 100px;
  min-height: 300px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 100px 5%;
  text-align: center;
  ${smallScreen} {
    padding: 50px 10px;
  }
`

export const HomeButton = styled(Button)`
  margin-top: 20px;
  height: 52px;
  width: max-content;
`

export const NotFound = () => {
  const { role } = useAppSelector(selectLogin)
  const navigate = useNavigate()
  return (
    <Wrapper>
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">
        We can't seem to find a page you're looking for.
      </Typography>
      <HomeButton
        variant="contained"
        onClick={() =>
          navigate(role === UserRole.ACCOUNT ? TRANSACTIONS : ROOT)
        }
      >
        Back to Homepage
      </HomeButton>
    </Wrapper>
  )
}

export const NotAuthorized = () => {
  const { role } = useAppSelector(selectLogin)
  const navigate = useNavigate()
  return (
    <Wrapper>
      <Typography variant="h1">401</Typography>
      <Typography variant="h4">
        You are not authorized to access this page.
      </Typography>
      <HomeButton
        variant="contained"
        onClick={() =>
          navigate(role === UserRole.ACCOUNT ? TRANSACTIONS : ROOT)
        }
      >
        Back to Homepage
      </HomeButton>
    </Wrapper>
  )
}
