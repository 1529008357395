import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

type VersionDialogState = {
  open: boolean
}

export const versionDialoginitialState: VersionDialogState = {
  open: false,
}

export const versionDialogSlice = createSlice({
  name: "versionDialog",
  initialState: versionDialoginitialState,
  reducers: {
    showDialog: (state) => {
      state.open = true
    },
    hideDialog: (state) => {
      state.open = false
    },
  },
})

export const { showDialog, hideDialog } = versionDialogSlice.actions

export const selectVersionDialog = (state: RootState) => state.versionDialog

export default versionDialogSlice.reducer
