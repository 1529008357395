import { useEffect, useState } from "react"
import {
  selectUsers,
  fetchUsers,
  setPage,
  setFilter,
} from "../../slices/usersSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import PageHeader from "../../components/PageHeader/PageHeader"
import { Stack } from "@mui/material"
import InviteUserForm from "./InviteUserForm"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import ElevatedCard from "../../components/ElevatedCard/ElevatedCard"
import UsersTable from "./UserTable"
import UserFilter from "./UserFilter"
import queryString from "query-string"
import { useLocation, useSearchParams } from "react-router-dom"

export function Users() {
  const { users, pageInfo, roles, isLoading, filters, pagination } =
    useAppSelector(selectUsers)
  const dispatch = useAppDispatch()
  const location = useLocation()
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams()
  const [open, setOpen] = useState(false)

  const setPaginationModel = (page: any) => {
    dispatch(setPage(page.page))
  }

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"))

  useEffect(() => {
    // URL Parameters to Redux State
    const params = queryString.parse(location.search)
    if (Object.keys(params).length !== 0) {
      // URL has filter parameters
      const updatedFilters = { ...filters, ...params }
      // Convert string values to arrays if necessary
      if (updatedFilters.status && !Array.isArray(updatedFilters.status)) {
        updatedFilters.status = [updatedFilters.status]
      }
      if (!Array.isArray(params.pageNo)) {
        const page = parseInt(params.pageNo || "0")
        dispatch(setPage(page - 1))
      }
      dispatch(setFilter(updatedFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredFilterState = Object.fromEntries(
      // eslint-disable-next-line
      Object.entries(filters).filter(([_, value]) => value !== ""),
    )

    const params = queryString.stringify({
      ...filteredFilterState,
      pageNo: pagination.page + 1,
    })

    setSearchParams(params)

    const isEmailValid = filteredFilterState.email
      ? /^\S+@\S+\.\S+$/.test(filters.email)
      : true

    if (isEmailValid) {
      dispatch(
        fetchUsers({
          ...filteredFilterState,
          ...pagination,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pagination.page, setSearchParams, filters])

  const handleRefresh = () => {
    const filteredFilterState = Object.fromEntries(
      // eslint-disable-next-line
      Object.entries(filters).filter(([_, value]) => value !== ""),
    )
    dispatch(
      fetchUsers({
        ...filteredFilterState,
        ...pagination,
      }),
    )
  }

  return (
    <>
      <PageHeader title="Users" />
      <Stack width="97%" margin="auto" gap={1} mt={1} pb={6}>
        <ElevatedCard
          style={{
            width: isSmallScreen ? "84%" : "97%",
            display: "flex",
          }}
        >
          <UserFilter
            handleRefresh={handleRefresh}
            roles={roles}
            isLargeScreen={isLargeScreen}
            isSmallScreen={isSmallScreen}
            isMediumScreen={isMediumScreen}
            setOpen={setOpen}
          />
        </ElevatedCard>
        <UsersTable
          users={users}
          roles={roles}
          isLoading={isLoading}
          pageInfo={pageInfo}
          paginationModel={pagination}
          setPaginationModel={setPaginationModel}
          handleRefresh={handleRefresh}
        />
      </Stack>
      {open && <InviteUserForm open={open} setOpen={setOpen} />}
    </>
  )
}

export default Users
