import { Stack, useMediaQuery, useTheme } from "@mui/material"
import PageHeader from "../../components/PageHeader/PageHeader"
import Filters from "./Filters"
import {
  getBankList,
  getBankNames,
  getPaymentModes,
  selectBankAccounts,
  resetFilters,
  setFiltersFromURL,
} from "../../slices/bankAccountsSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect } from "react"
import { BankTable } from "./BankTable"
import queryString from "query-string"
import { useLocation, useNavigate } from "react-router-dom"

export const BankAccounts = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { bankList, isbankListLoading, filters, isDeactivated } =
    useAppSelector(selectBankAccounts)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"))

  useEffect(() => {
    dispatch(getPaymentModes())
    dispatch(getBankNames())

    // URL Parameters to Redux State
    const params = queryString.parse(location.search)
    if (Object.keys(params).length !== 0) {
      const updatedFilters = { ...filters, ...params }
      dispatch(setFiltersFromURL(updatedFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // set filters to URL for sharing purpose
    const params = queryString.stringify(filters)
    navigate({ search: params })
    dispatch(getBankList(filters))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters])

  //  refetch list after successfull deactivation with applied filters
  useEffect(() => {
    if (isDeactivated) dispatch(getBankList(filters))
  }, [isDeactivated, dispatch, filters])

  // reset filter handler
  const resetFilterHandler = () => {
    dispatch(resetFilters())
  }

  //handle refresh
  const handleRefresh = () => {
    dispatch(getBankList(filters))
  }

  return (
    <>
      <PageHeader title="Bank Accounts" />
      <Stack mx={1} width="97%" margin="auto" gap={1} mt={1} pb={6}>
        <Filters
          handleRefresh={handleRefresh}
          isMediumScreen={isMediumScreen}
          isSmallScreen={isSmallScreen}
          resetFilterHandler={resetFilterHandler}
        />
        <BankTable bankList={bankList} isLoading={isbankListLoading} />
      </Stack>
    </>
  )
}
