// All Routes of the Application are declared here
export const ROOT = "/"
export const LOGIN = "/login"
export const BOOKING_HISTORY = "/booking-history"
export const USERS = `/users`
export const USER_PROFILE = `${USERS}/:userId/profile`
export const USER_BOOKING_DETAILS = `${USERS}/:userId/booking-history`
export const USER_AUDIT_LOG = `${USERS}/:userId/audit-logs`
export const CENTERS = "/centers"
export const ADD_CENTER = `${CENTERS}/:centerId`
export const CENTER_DETAILS = `${CENTERS}/details/:centerId`
export const MEETING_ROOMS = "/meeting-rooms"
export const ADD_MEETING_ROOM = `${MEETING_ROOMS}/:id`
export const AUDIT_LOGS = `/audit-logs`
export const TRANSACTIONS = `/transactions`
export const BANK_ACCOUNTS = `/bank-accounts`
export const BANK_ACCOUNTS_VIEW = `/bank-accounts/:bankAccountId`
