import styled from "@emotion/styled"

export const Wrapper = styled.div`
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 60px;
  padding-right: 60px;
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 1000;
`
