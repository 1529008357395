import { Autocomplete, Stack, TextField } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { inviteUser, selectUsers } from "../../slices/usersSlice"
import { useRef, useState } from "react"

type InviteUserFormProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface FormValues {
  roleId: string
  centerId: string
  firstName: string
  lastName: string
  email: string
  mobileNo: string
}

interface ErrorValues {
  mobileNo: string
}

export function InviteUserForm({
  open,
  setOpen,
}: InviteUserFormProps): JSX.Element {
  const { roles } = useAppSelector(selectUsers)
  const dispatch = useAppDispatch()

  const [formValues, setFormValues] = useState<FormValues>({
    roleId: "",
    centerId: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
  })

  const [validationErrors, setValidationErrors] = useState<ErrorValues>({
    mobileNo: ''
  })
  const typingTimer = useRef<NodeJS.Timeout | null>(null);

  // input change handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (typingTimer.current) {
      clearTimeout(typingTimer.current);
    }

    typingTimer.current = setTimeout(() => {
      let error = "";
      if (name === "mobileNo") {
        // Basic mobile number validation
        if (!/^\d{10}$/.test(value)) {
          error = "Invalid mobile number (10 digits)";
        }
      }

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }, 500);

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }))
  }

  // submit button hanlder
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const hasErrors = Object.values(validationErrors).some((error) => error !== "");
    if (!hasErrors) {
      dispatch(
        inviteUser({
          ...formValues,
          page: 0,
          pageSize: 10,
          name: "",
        }),
      )
    }
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="invite-user-dialog-title"
      aria-describedby="invite-user-dialog-description"
    >
      <DialogTitle id="invite-user-dialog-title">Add User Details</DialogTitle>
      <DialogContent>
        <Stack
          component="form"
          gap={2}
          onSubmit={handleSubmit}
          autoComplete="off"
          pt="10px"
        >
          <Autocomplete
            fullWidth
            id="roles"
            options={roles}
            getOptionLabel={(option) => option.name}
            value={roles.find((role) => role.id === formValues.roleId) || null}
            onChange={(_, newValue) => {
              setFormValues((prevValues) => ({
                ...prevValues,
                roleId: newValue?.id || "",
              }))
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Roles"
                variant="outlined"
                size="small"
                required
                InputLabelProps={{ shrink: true }}
              />
            )}
          />

          <Stack direction="row" gap={2}>
            <TextField
              size="small"
              name="firstName"
              label="First Name"
              value={formValues.firstName}
              onChange={handleInputChange}
              fullWidth
              required
            />

            <TextField
              size="small"
              name="lastName"
              label="Last Name"
              value={formValues.lastName}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Stack>

          <TextField
            size="small"
            name="email"
            label="Email"
            type="email"
            value={formValues.email}
            onChange={handleInputChange}
            fullWidth
            required
          />

          <TextField
            size="small"
            name="mobileNo"
            label="Mobile No."
            type="tel"
            value={formValues.mobileNo}
            onChange={handleInputChange}
            error={Boolean(validationErrors.mobileNo)}
            helperText={validationErrors.mobileNo}
            fullWidth
            required
          />

          <Stack direction="row" gap={14}>
            <Button
              type="submit"
              sx={{ flexGrow: 1 }}
              variant="contained"
              color="primary"
            >
              Invite
            </Button>

            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="secondary"
              size="small"
              sx={{ flexGrow: 1 }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default InviteUserForm
