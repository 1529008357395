import { takeLatest, put, call } from "redux-saga/effects"
import {
  createBankAccountService,
  getBankListService,
  getPaymentModesService,
  deactivateBankAccountService,
  getBankNameListService,
  getBankdetailsService,
} from "../services/bankAccountService"
import {
  createAccountPayloadType,
  createBankAccount,
  createBankAccountFailed,
  createBankAccountSuccess,
  getBankList,
  getBankListSuccess,
  getBankListFailed,
  getPaymentModes,
  getPaymentModesSuccess,
  getPaymentModesFailed,
  deactivateBankAccount,
  deactivateBankAccountFailed,
  deactivateBankAccountSuccess,
  deactivateBankAccountPayloadType,
  getBankNames,
  getBankNamesSuccess,
  getBankNamesFailed,
  getBankDetails,
  getBankDetailsFailed,
  getBankDetailsSuccess,
  getBankDetailsPayloadType,
  getBankListPayloadType,
} from "../slices/bankAccountsSlice"
import { AxiosResponse } from "axios"
import {
  Bank,
  BankName,
  BankResponse,
  PaymentMode,
  PaymentModeResponse,
} from "../models/bank.model"
import { showToast } from "../slices/toastSlice"

export function* createBankAccountSaga(action: createAccountPayloadType) {
  try {
    yield call(createBankAccountService, action)
    yield put(createBankAccountSuccess())
    yield put(
      showToast({
        toastType: "success",
        msg: "Bank account created successfully",
      }),
    )
  } catch (err) {
    yield put(createBankAccountFailed())
  }
}

export function* getBankListSaga(action: getBankListPayloadType) {
  try {
    const { data }: AxiosResponse = yield call(getBankListService, action)
    yield put(
      getBankListSuccess({
        bankList: data.data.map((bank: BankResponse) => new Bank(bank)),
      }),
    )
  } catch (err) {
    yield put(getBankListFailed())
  }
}

export function* getPaymentModesSaga() {
  try {
    const { data }: AxiosResponse = yield call(getPaymentModesService)
    yield put(
      getPaymentModesSuccess({
        paymentModes: data.data.map(
          (paymentMode: PaymentModeResponse) => new PaymentMode(paymentMode),
        ),
      }),
    )
  } catch (err) {
    yield put(getPaymentModesFailed())
  }
}

export function* deactivateBankAccountSaga(
  action: deactivateBankAccountPayloadType,
) {
  try {
    yield call(deactivateBankAccountService, action)
    yield put(deactivateBankAccountSuccess())
    yield put(
      showToast({
        toastType: "success",
        msg: "Bank account deactivated successfully",
      }),
    )
  } catch (err) {
    yield put(deactivateBankAccountFailed())
  }
}

export function* getBankNamesSaga() {
  try {
    const { data }: AxiosResponse = yield call(getBankNameListService)
    yield put(
      getBankNamesSuccess({
        bankNames: data.data.map(
          (bankName: BankName) => new BankName(bankName),
        ),
      }),
    )
  } catch (err) {
    yield put(getBankNamesFailed())
  }
}

export function* getBankDetailsSaga(action: getBankDetailsPayloadType) {
  try {
    const { data }: AxiosResponse = yield call(getBankdetailsService, action)
    yield put(
      getBankDetailsSuccess({
        bankDetails: new Bank(data.data),
      }),
    )
  } catch (err) {
    yield put(getBankDetailsFailed())
  }
}

export default function* bankAccountsSaga() {
  yield takeLatest(createBankAccount, createBankAccountSaga)
  yield takeLatest(getBankList, getBankListSaga)
  yield takeLatest(getPaymentModes, getPaymentModesSaga)
  yield takeLatest(deactivateBankAccount, deactivateBankAccountSaga)
  yield takeLatest(getBankNames, getBankNamesSaga)
  yield takeLatest(getBankDetails, getBankDetailsSaga)
}
