import styled from "@emotion/styled"

// Media Query for small screens (e.g., mobile)
const smallScreen = "@media (max-width: 768px)"

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 40px 0px;
  margin-top: 16px;

  ${smallScreen} {
    grid-template-columns: 1fr;
    gap: 20px 0px;
  }
}
`

export const ListItemCard = styled.div`
  width: 220px;
  height: 100px;
  padding: 20px;
  box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
  border-radius: 6px;
  background: #fff;

  &:hover {
    cursor: pointer;
    transform: scale(1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  ${smallScreen} {
    width: 80%;
  }
`

export const CenterDropDownWrapper = styled.div`
  background: white;
  margin-left: 0px;
  margin-top: 10px;
  width: 98%;

  ${smallScreen} {
    margin: 10px auto;
    width: 90%;
  }
`
