import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { Bank, BankName, PaymentMode } from "../models/bank.model"

type FilterType = {
  search: string
  bankName: string
  paymentMode: string
  source: string
  invoiceSource: string
  isActive: string
}

type BankAccountState = {
  createAccount: {
    isCreated: boolean
    isLoading: boolean
  }
  bankList: Bank[]
  isbankListLoading: boolean
  paymentModes: PaymentMode[]
  isPaymentModesLoading: boolean
  bankNames: BankName[]
  isBankNamesLoading: boolean
  bankDetails: Bank
  isBankDetailsLoading: boolean
  filters: FilterType
  isDeactivated: boolean
}

const initialBankDetails = {
  id: "",
  accountNumber: "",
  accountName: "",
  bankName: "",
  source: "",
  invoiceSource: "",
  zohoAccountId: "",
  lastSyncedTransactionId: "",
  lastSyncedTransactions: "",
  lastReconciledId: "",
  paymentMode: "",
  isActive: true,
  isDeactivated: false,
}

const initialFilterState = {
  search: "",
  bankName: "all",
  paymentMode: "all",
  source: "",
  invoiceSource: "",
  isActive: "yes",
}

export const bankAccountsInitialState: BankAccountState = {
  createAccount: {
    isCreated: false,
    isLoading: false,
  },
  bankList: [],
  isbankListLoading: false,
  paymentModes: [],
  isPaymentModesLoading: false,
  bankNames: [],
  isBankNamesLoading: false,
  bankDetails: initialBankDetails,
  isBankDetailsLoading: false,
  filters: initialFilterState,
  isDeactivated: false,
}

export type createAccountPayloadType = {
  payload: {
    accountName: string
    accountNumber: string
    bankName: string
    paymentMode: string
    owner: string
    invoiceSource: string
    zohoAccountId: string
    bankAccountId?: string
  }
}

export type deactivateBankAccountPayloadType = {
  payload: {
    accountId: string
  }
}

export type getBankDetailsPayloadType = deactivateBankAccountPayloadType

export type getBankListPayloadType = {
  payload: FilterType
}

export const bankAccountSlice = createSlice({
  name: "bankAccounts",
  initialState: bankAccountsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createBankAccount: (state, action: createAccountPayloadType) => {
      state.createAccount.isLoading = true
    },
    createBankAccountSuccess: (state) => {
      state.createAccount.isLoading = false
      state.createAccount.isCreated = true
    },
    createBankAccountFailed: (state) => {
      state.createAccount.isLoading = false
    },
    resetCreateAccountState: (state) => {
      state.createAccount.isCreated = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getBankList: (state, action: getBankListPayloadType) => {
      state.isbankListLoading = true
      state.isDeactivated = false
    },
    getBankListSuccess: (
      state,
      { payload: { bankList } }: { payload: { bankList: Bank[] } },
    ) => {
      state.bankList = bankList
      state.isbankListLoading = false
    },
    getBankListFailed: (state) => {
      state.isbankListLoading = false
    },

    getPaymentModes: (state) => {
      state.isPaymentModesLoading = true
    },
    getPaymentModesSuccess: (
      state,
      {
        payload: { paymentModes },
      }: { payload: { paymentModes: PaymentMode[] } },
    ) => {
      state.paymentModes = paymentModes
      state.isPaymentModesLoading = true
    },
    getPaymentModesFailed: (state) => {
      state.isPaymentModesLoading = false
    },

    deactivateBankAccount: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: deactivateBankAccountPayloadType,
    ) => {
      state.isbankListLoading = true
    },
    deactivateBankAccountSuccess: (state) => {
      state.isDeactivated = true
      state.isbankListLoading = false
    },
    deactivateBankAccountFailed: (state) => {
      state.isbankListLoading = false
    },

    getBankNames: (state) => {
      state.isBankNamesLoading = true
    },
    getBankNamesSuccess: (
      state,
      { payload: { bankNames } }: { payload: { bankNames: BankName[] } },
    ) => {
      state.bankNames = bankNames
      state.isBankNamesLoading = true
    },
    getBankNamesFailed: (state) => {
      state.isBankNamesLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getBankDetails: (state, action: getBankDetailsPayloadType) => {
      state.isBankDetailsLoading = true
    },
    getBankDetailsSuccess: (
      state,
      { payload: { bankDetails } }: { payload: { bankDetails: Bank } },
    ) => {
      state.bankDetails = bankDetails
      state.isBankDetailsLoading = false
    },
    getBankDetailsFailed: (state) => {
      state.isBankDetailsLoading = false
    },

    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    setFiltersFromURL: (state, { payload }) => {
      state.filters = payload
    },
    resetFilters: (state) => {
      state.filters = initialFilterState
    },
  },
})

export const {
  createBankAccount,
  createBankAccountSuccess,
  createBankAccountFailed,
  resetCreateAccountState,
  getBankList,
  getBankListFailed,
  getBankListSuccess,
  getPaymentModes,
  getPaymentModesSuccess,
  getPaymentModesFailed,
  deactivateBankAccount,
  deactivateBankAccountFailed,
  deactivateBankAccountSuccess,
  getBankNames,
  getBankNamesSuccess,
  getBankNamesFailed,
  getBankDetails,
  getBankDetailsFailed,
  getBankDetailsSuccess,
  setFilters,
  resetFilters,
  setFiltersFromURL,
} = bankAccountSlice.actions

export const selectBankAccounts = (state: RootState) => state.bankAccounts

export default bankAccountSlice.reducer
