import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { Stack } from "@mui/system"
import { FormikProps } from "formik"
import { useNavigate } from "react-router-dom"
import { BANK_ACCOUNTS } from "../../constants/path"
import LoadingButton from "@mui/lab/LoadingButton"

const Form = ({
  errors,
  handleChange,
  handleBlur,
  values,
  handleSubmit,
  setFieldValue,
  isLoading,
}: { isLoading: boolean } & FormikProps<any>) => {
  const navigate = useNavigate()
  return (
    <Stack gap={2}>
      <TextField
        name="accountName"
        label="Account Name"
        size="small"
        required
        value={values.accountName || ""}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!errors.accountName}
        helperText={errors.accountName as string}
      />
      <TextField
        name="accountNumber"
        label="Account Number"
        size="small"
        required
        value={values.accountNumber || ""}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!errors.accountNumber}
        helperText={errors.accountNumber as string}
      />
      <TextField
        name="bankName"
        label="Bank Name"
        size="small"
        required
        value={values.bankName || ""}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!errors.bankName}
        helperText={errors.bankName as string}
      />
      <FormControl fullWidth size="small">
        <InputLabel id="paymentMode-label">Payment Mode</InputLabel>
        <Select
          labelId="paymentMode-label"
          id="paymentMode-select"
          label="Payment Mode"
          name="paymentMode"
          required
          value={values.paymentMode || ""}
          onBlur={handleBlur}
          onChange={(e) => setFieldValue("paymentMode", e.target.value)}
          error={!!errors.paymentMode}
        >
          <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
          <MenuItem value="netbanking">Netbanking</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel id="owner-label">Owner</InputLabel>
        <Select
          labelId="owner-label"
          id="owner-select"
          label="Owner"
          name="owner"
          required
          value={values.owner || ""}
          onBlur={handleBlur}
          onChange={(e) => setFieldValue("owner", e.target.value)}
          error={!!errors.owner}
        >
          <MenuItem value="alts">Alts</MenuItem>
          <MenuItem value="workspace">Workspace</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel id="invoiceSource-label">Invoice Source</InputLabel>
        <Select
          labelId="invoiceSource-label"
          id="invoiceSource-select"
          label="Invoice Source"
          name="invoiceSource"
          value={values.invoiceSource || ""}
          onBlur={handleBlur}
          onChange={(e) => setFieldValue("invoiceSource", e.target.value)}
          error={!!errors.invoiceSource}
        >
          <MenuItem value="zenith">Zenith</MenuItem>
          <MenuItem value="tusker">Tusker</MenuItem>
        </Select>
      </FormControl>

      <TextField
        name="zohoAccountId"
        label="Zoho Account Id"
        size="small"
        required={values.owner === "workspace"}
        value={values.zohoAccountId || ""}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!errors.zohoAccountId}
        helperText={errors.zohoAccountId as string}
      />
      <Stack direction="row" gap={10}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          onClick={() => navigate(BANK_ACCOUNTS)}
          fullWidth
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={handleSubmit as any}
          fullWidth
        >
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default Form
