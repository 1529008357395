import Table from "../../components/Table/Table"
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material"
import { GridColDef, GridRowsProp } from "@mui/x-data-grid"
import { Bank } from "../../models/bank.model"
import { useNavigate } from "react-router-dom"
import { computeRoute } from "../../utils/compute-route"
import { BANK_ACCOUNTS_VIEW } from "../../constants/path"
import { useState } from "react"
import { deactivateBankAccount } from "../../slices/bankAccountsSlice"
import { useAppDispatch } from "../../app/hooks"

interface bankTableProps {
  bankList: Bank[]
  isLoading: boolean
}

export interface ConfirmDialogInterface {
  open: boolean
  accountId: string
}

const confirmDialogInitialState = {
  open: false,
  accountId: "",
}

export const BankTable = ({ bankList, isLoading }: bankTableProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogInterface>(
    confirmDialogInitialState,
  )

  const columns: GridColDef[] = [
    {
      field: "accountNumber",
      headerName: "Account Number",
      width: 200,
      sortable: false,
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 200,
      sortable: false,
    },
    { field: "bankName", headerName: "Bank Name", width: 200, sortable: false },
    { field: "source", headerName: "Owner", width: 200, sortable: false },
    {
      field: "invoiceSource",
      headerName: "Invoice Source",
      width: 200,
      sortable: false,
    },
    {
      field: "zohoAccountId",
      headerName: "Zoho Account ID",
      width: 200,
      sortable: false,
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      width: 200,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" gap={1}>
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                navigate(
                  computeRoute(BANK_ACCOUNTS_VIEW, { bankAccountId: row.id }),
                )
              }
            >
              Edit
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={!row.isActive}
              onClick={() =>
                setConfirmDialog({ open: true, accountId: row.id })
              }
            >
              Deactivate
            </Button>
          </Stack>
        )
      },
    },
  ]

  const rows: GridRowsProp = bankList.map((bank) => ({
    id: bank.id,
    accountNumber: bank.accountNumber,
    accountName: bank.accountName,
    bankName: bank.bankName,
    source: bank.source,
    invoiceSource: bank.invoiceSource,
    zohoAccountId: bank.zohoAccountId,
    paymentMode: bank.paymentMode,
    isActive: bank.isActive,
  }))

  const handleConfirm = () => {
    dispatch(deactivateBankAccount({ accountId: confirmDialog.accountId }))
    setConfirmDialog(confirmDialogInitialState)
  }

  const handleClose = () => {
    setConfirmDialog(confirmDialogInitialState)
  }

  return (
    <>
      <Table
        rows={rows}
        columns={columns}
        loading={isLoading}
        rowCount={bankList.length}
        pageSizeOptions={[10]}
      />
      <Dialog
        open={confirmDialog.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to deactivate this account?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
