import { object, ObjectSchema, string } from "yup"
import {
  createAccountPayloadType,
  getBankListPayloadType,
} from "../slices/bankAccountsSlice"

export interface BankResponse {
  id: string
  account_number: string
  account_name: string
  bank_name: string
  source: string
  invoice_source: string
  zoho_account_id: string
  last_synced_transaction_id: string
  last_synced_transactions: string
  last_reconciled_id: string
  payment_mode: string
  is_active: boolean
}
export class Bank {
  id = ""
  accountNumber = ""
  accountName = ""
  bankName = ""
  source = ""
  invoiceSource = ""
  zohoAccountId = ""
  lastSyncedTransactionId = ""
  lastSyncedTransactions = ""
  lastReconciledId = ""
  paymentMode = ""
  isActive = true

  constructor(bank: BankResponse) {
    if (bank) {
      this.id = bank.id
      this.accountNumber = bank.account_number
      this.accountName = bank.account_name
      this.bankName = bank.bank_name
      this.source = bank.source
      this.invoiceSource = bank.invoice_source
      this.zohoAccountId = bank.zoho_account_id
      this.lastSyncedTransactionId = bank.last_synced_transaction_id
      this.lastSyncedTransactions = bank.last_synced_transactions
      this.lastReconciledId = bank.last_reconciled_id
      this.paymentMode = bank.payment_mode
      this.isActive = bank.is_active
    }
  }

  static addBankFormSchema: ObjectSchema<any> = object({
    accountName: string().trim().default("").required().label("Account Name"),
    accountNumber: string()
      .trim()
      .default("")
      .required()
      .label("Account Number"),
    bankName: string().trim().default("").required().label("Bank Name"),
    paymentMode: string().trim().default("").required().label("Payment Mode"),
    owner: string().trim().default("").required().label("Owner"),
    invoiceSource: string()
      .trim()
      .default("")
      .required()
      .label("Invoice Source"),
    zohoAccountId: string()
      .trim()
      .when("owner", (owner, schema) => {
        if (owner[0] === "workspace")
          return schema.required(
            "Zoho Account ID is required for workspace owners",
          )
        return schema
      })
      .label("Zoho Account Id"),
  })

  static bankListParamsDTO = ({ payload }: getBankListPayloadType): any => ({
    ...(payload.search !== "" && {
      search: payload.search,
    }),
    ...(payload.bankName !== "all" && {
      bank_name: payload.bankName,
    }),
    ...(payload.paymentMode !== "all" && {
      payment_mode: payload.paymentMode,
    }),
    ...(payload.source !== "" && {
      source: payload.source,
    }),
    ...(payload.invoiceSource !== "" && {
      invoice_source: payload.invoiceSource,
    }),
    is_active: payload.isActive === "yes" ? true : false,
  })

  static createBankAccountDTO = ({
    payload,
  }: createAccountPayloadType): any => ({
    number: payload.accountNumber,
    name: payload.accountName,
    bank_name: payload.bankName,
    source: payload.owner,
    zoho_account_id: payload.zohoAccountId,
    payment_mode: payload.paymentMode,
    invoice_source: payload.invoiceSource,
    ...(payload.bankAccountId !== "" && {
      bank_account_id: payload.bankAccountId,
    }),
  })
}

export interface BankNameResponse {
  id: string
  name: string
}

export class BankName {
  id = ""
  name = ""

  constructor(bankName: BankNameResponse) {
    if (bankName) {
      this.id = bankName.id
      this.name = bankName.name
    }
  }
}

export interface PaymentModeResponse {
  id: string
  name: string
}

export class PaymentMode {
  id = ""
  name = ""

  constructor(paymentMode: PaymentModeResponse) {
    if (paymentMode) {
      this.id = paymentMode.id
      this.name = paymentMode.name
    }
  }
}
