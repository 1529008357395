import React from "react"
import {
  Stack,
  Tooltip,
  Button,
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material"
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import { useNavigate } from "react-router-dom"
import { computeRoute } from "../../utils/compute-route"
import { BANK_ACCOUNTS_VIEW } from "../../constants/path"
import { selectBankAccounts, setFilters } from "../../slices/bankAccountsSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

interface FiltersProps {
  isSmallScreen: boolean
  isMediumScreen: boolean
  handleRefresh: () => void
  resetFilterHandler: () => void
}

const Filters: React.FC<FiltersProps> = ({
  isSmallScreen,
  isMediumScreen,
  handleRefresh,
  resetFilterHandler,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    filters,
    isBankNamesLoading,
    bankNames,
    paymentModes,
    isPaymentModesLoading,
  } = useAppSelector(selectBankAccounts)
  return (
    <Box
      style={{
        padding: 20,
        marginBottom: "10px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 2,
          gridTemplateColumns: isSmallScreen
            ? "1fr"
            : isMediumScreen
            ? "repeat(3, 9fr)"
            : "repeat(5, 9fr)",
          width: "100%",
        }}
      >
        <FormControl size="small">
          <TextField
            size="small"
            variant="outlined"
            label="Search"
            placeholder="Search by Account Name, Number & Zoho Id"
            value={filters.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setFilters({ ...filters, search: e.target.value }))
            }}
          />
        </FormControl>

        <FormControl size="small">
          <InputLabel id="select-bank-name">Bank Name</InputLabel>
          <Select
            labelId="select-bank-name"
            id="selectBankName"
            label="Bank Name"
            value={filters.bankName}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  bankName: e.target.value as string,
                }),
              )
            }
            disabled={!isBankNamesLoading}
          >
            <MenuItem value="all">All</MenuItem>
            {bankNames.map((bank) => (
              <MenuItem key={bank.id} value={bank.id}>
                {bank.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="select-payment-mode">Payment Mode</InputLabel>
          <Select
            labelId="select-payment-mode"
            id="selectPaymentMode"
            label="Payment Mode"
            value={filters.paymentMode}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  paymentMode: e.target.value as string,
                }),
              )
            }
            disabled={!isPaymentModesLoading}
          >
            <MenuItem value="all">All</MenuItem>
            {paymentModes.map((mode) => (
              <MenuItem key={mode.id} value={mode.id}>
                {mode.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="select-source">Source</InputLabel>
          <Select
            labelId="select-source"
            id="selectSource"
            label="Source"
            value={filters.source}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  source: e.target.value as string,
                }),
              )
            }
          >
            <MenuItem value="alts">Alts</MenuItem>
            <MenuItem value="workspace">Workspace</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="select-invoice-source">Invoice Source</InputLabel>
          <Select
            labelId="select-invoice-source"
            id="selectInvoiceSource"
            label="Invoice Source"
            value={filters.invoiceSource}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  invoiceSource: e.target.value as string,
                }),
              )
            }
          >
            <MenuItem value="tusker">Tusker</MenuItem>
            <MenuItem value="zenith">Zenith</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="select-is-active">Is Active</InputLabel>
          <Select
            labelId="select-is-active"
            id="selectIsActive"
            label="Is Active"
            value={filters.isActive}
            onChange={(e: SelectChangeEvent) =>
              dispatch(
                setFilters({
                  ...filters,
                  isActive: e.target.value as string,
                }),
              )
            }
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 1,
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          gap={2}
          marginTop={"10px"}
          justifyContent={isSmallScreen ? "center" : "flex-start"}
          style={{ gridColumn: isSmallScreen ? 1 : 1 }}
        >
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                computeRoute(BANK_ACCOUNTS_VIEW, { bankAccountId: "add" }),
              )
            }
            sx={{
              width: isSmallScreen ? "50%" : "150px",
              height: "36px",
              mx: isSmallScreen ? "auto" : "0",
            }}
          >
            Add Account
          </Button>
        </Stack>
        <Stack
          direction="row"
          gap={2}
          marginTop={"10px"}
          alignItems="center"
          justifyContent={isSmallScreen ? "center" : "flex-end"}
          style={{ gridColumn: isSmallScreen ? 1 : 5 }}
        >
          <Tooltip title="Refresh">
            <Button
              variant="outlined"
              startIcon={<RefreshRoundedIcon />}
              onClick={handleRefresh}
              style={{ overflow: "hidden" }}
            >
              Refresh
            </Button>
          </Tooltip>
          <Tooltip title="Reset Filter" arrow>
            <Button
              variant="outlined"
              startIcon={<CachedOutlinedIcon />}
              onClick={resetFilterHandler}
              style={{
                overflow: "hidden",
              }}
            >
              Reset
            </Button>
          </Tooltip>
        </Stack>
      </Box>
    </Box>
  )
}

export default Filters
