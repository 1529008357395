import Dialog from "@mui/material/Dialog"
import {
  CloseIconButton,
  DialogContent,
  DialogData,
  DialogDataHeading,
  DialogDataRow,
} from "./styles"
import { Transaction } from "../../models/transactions.model"
import { Button, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import dayjs from "dayjs"
import { useState } from "react"
import ReconcileForm from "../ReconcileForm/Index"
import BalanceIcon from "@mui/icons-material/Balance"
import { ListingRowMenu } from "../../components/ListingRowMenu.tsx"
import { allowedOnlyBankAccountsRoles } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"
import { useAppSelector } from "../../app/hooks"

type TransactionDetailProps = {
  open: boolean
  onClose: () => void
  transactionData: Transaction
}

const TransactionDetail = ({
  open,
  onClose,
  transactionData,
}: TransactionDetailProps) => {
  const [reconcileDialogOpen, setReconcileDialogOpen] = useState(false)
  const { role } = useAppSelector(selectLogin)

  const handleReconcileDialogOpen = () => {
    setReconcileDialogOpen(true)
  }

  const handleReconcileDialogClose = () => {
    setReconcileDialogOpen(false)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
          <Typography variant="h5">Transaction Details</Typography>
          <DialogDataRow>
            <DialogDataHeading>ID:</DialogDataHeading>
            <DialogData>{transactionData.id}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Date:</DialogDataHeading>
            <DialogData>
              {transactionData.date
                ? dayjs
                    .utc(transactionData.date)
                    .utcOffset("+05:30")
                    .format("DD/MM/YYYY")
                : ""}
            </DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Description:</DialogDataHeading>
            <DialogData>{transactionData.description}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Amount:</DialogDataHeading>
            <DialogData>{transactionData.amount}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Type:</DialogDataHeading>
            <DialogData>{transactionData.type}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Reference No:</DialogDataHeading>
            <DialogData>{transactionData.referenceNo}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Value Date:</DialogDataHeading>
            <DialogData>
              {transactionData.valueDate
                ? dayjs
                    .utc(transactionData.valueDate)
                    .utcOffset("+05:30")
                    .format("DD/MM/YYYY")
                : ""}
            </DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Branch:</DialogDataHeading>
            <DialogData>{transactionData.branch}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Balance:</DialogDataHeading>
            <DialogData>{transactionData.balance}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Marked:</DialogDataHeading>
            <DialogData>{transactionData.isMarked ? "Yes" : "No"}</DialogData>
          </DialogDataRow>
          {allowedOnlyBankAccountsRoles.includes(role) ? (
            <DialogDataRow>
              <DialogDataHeading>Invoice Number:</DialogDataHeading>
              <DialogData>
                <ListingRowMenu
                  name="invoice"
                  options={transactionData?.settlements?.invoices?.map(
                    (invoice: {
                      invoice_url: string
                      invoice_number: string
                    }) => {
                      return {
                        value: invoice.invoice_url,
                        name: invoice.invoice_number,
                      }
                    },
                  )}
                />
              </DialogData>
            </DialogDataRow>
          ) : null}
          {allowedOnlyBankAccountsRoles.includes(role) ? (
            <DialogDataRow>
              <DialogDataHeading>Payment Id:</DialogDataHeading>
              <DialogData>
                <ListingRowMenu
                  name="payment"
                  options={transactionData?.settlements?.payments?.map(
                    (payment: {
                      payment_url: string
                      payment_number: string
                    }) => {
                      return {
                        value: payment.payment_url,
                        name: payment.payment_number,
                      }
                    },
                  )}
                />
              </DialogData>
            </DialogDataRow>
          ) : null}
          <DialogDataRow>
            <DialogDataHeading>Payee:</DialogDataHeading>
            <DialogData>{transactionData.payee}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Bank Name:</DialogDataHeading>
            <DialogData>{transactionData.accountName}</DialogData>
          </DialogDataRow>
          <DialogDataRow>
            <DialogDataHeading>Bank Name:</DialogDataHeading>
          </DialogDataRow>
          {transactionData.isMarked ||
          transactionData.isReconciled ||
          transactionData.type !== "C" ||
          !transactionData.isSynced ? null : (
            <DialogDataRow>
              <Button
                style={{ marginBottom: "10px", gap: "10px" }}
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleReconcileDialogOpen()}
              >
                Reconcile Manually <BalanceIcon />
              </Button>
            </DialogDataRow>
          )}
        </DialogContent>
      </Dialog>
      {reconcileDialogOpen && (
        <ReconcileForm
          open={reconcileDialogOpen}
          onClose={handleReconcileDialogClose}
          transactionData={transactionData}
        />
      )}
    </>
  )
}

export default TransactionDetail
