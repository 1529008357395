import axiosConfig from "../app/axiosConfig"
import {
  BANK_ACCOUNT_API,
  BANK_ACCOUNT_BY_ID_API,
  BANK_ACCOUNT_LIST_API,
  BANK_NAME_LIST_API,
  DEACTIVATE_BANK_ACCOUNT_API,
  PAYMENT_MODE_API,
} from "../constants/apis"
import { Bank } from "../models/bank.model"
import {
  createAccountPayloadType,
  deactivateBankAccountPayloadType,
  getBankDetailsPayloadType,
  getBankListPayloadType,
} from "../slices/bankAccountsSlice"
import { computeRoute } from "../utils/compute-route"

export const createBankAccountService = (action: createAccountPayloadType) =>
  axiosConfig.post(BANK_ACCOUNT_API, Bank.createBankAccountDTO(action))

export const getBankListService = (action: getBankListPayloadType) =>
  axiosConfig.get(BANK_ACCOUNT_LIST_API, {
    params: Bank.bankListParamsDTO(action),
  })

export const getPaymentModesService = () => axiosConfig.get(PAYMENT_MODE_API)

export const deactivateBankAccountService = ({
  payload,
}: deactivateBankAccountPayloadType) =>
  axiosConfig.post(
    computeRoute(DEACTIVATE_BANK_ACCOUNT_API, { accountId: payload.accountId }),
  )

export const getBankNameListService = () => axiosConfig.get(BANK_NAME_LIST_API)

export const getBankdetailsService = ({ payload }: getBankDetailsPayloadType) =>
  axiosConfig.get(
    computeRoute(BANK_ACCOUNT_BY_ID_API, { accountId: payload.accountId }),
  )
