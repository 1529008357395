import { takeLatest, put, call } from "redux-saga/effects"
import {
  login,
  loginSuccess,
  loginFailed,
  logout,
  loginPayloadType,
} from "../slices/loginSlice"
import { AxiosResponse } from "axios"
import { fetchLoginService } from "../services/loginService"

export function* fetchLoginSaga(action: loginPayloadType) {
  try {
    const { data }: AxiosResponse = yield call(
      fetchLoginService,
      action.payload.email,
      action.payload.password,
    )
    const name = `${data?.data?.user?.first_name} ${data?.data?.user?.last_name}`
    localStorage.setItem("jwt", data.data.access_token)
    localStorage.setItem("role", data?.data?.user?.roles[0].name)
    localStorage.setItem("name", name)
    localStorage.setItem("refreshToken", data?.data?.refresh_token)
    yield put(
      loginSuccess({
        token: data.data.access_token,
        role: data?.data?.user?.roles[0].name,
        name,
      }),
    )
  } catch (err) {
    yield put(loginFailed())
  }
}

// eslint-disable-next-line require-yield
export function* logoutUserSaga() {
  localStorage.clear()
}

export default function* loginSaga() {
  yield takeLatest(login, fetchLoginSaga)
  yield takeLatest(logout, logoutUserSaga)
}
