/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"

export type userType = {
  id: string
  mobile: string
  email: string
  first_name: string
  last_name: string
  status?: string
  roles?: rolesType[]
  profile_image?: string
  kyc_image?: string
  created_at?: string
  last_login?: string
}

export type rolesType = {
  id: string
  name: string
  type: string
}

export type FilterState = {
  firstName: string
  lastName: string
  email: string
  mobile: string
  status: string[] | string
  role: string
}

export type Pagination = {
  page: number
  pageSize: number
}

const initialPageState: Pagination = { page: 0, pageSize: 10 }

const initialFilterState: FilterState = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  status: [],
  role: "",
}

type UsersState = {
  users: userType[]
  roles: rolesType[]
  pageInfo: {
    totalRowCount: number
  }
  isLoading: boolean
  filters: FilterState
  pagination: Pagination
}

export const usersInitialState: UsersState = {
  users: [],
  roles: [],
  pageInfo: {
    totalRowCount: 0,
  },
  isLoading: false,
  filters: initialFilterState,
  pagination: initialPageState,
}

export type fetchUserPayloadType = {
  payload: {
    page: number
    pageSize: number
    name?: string
    mobile?: string
    firstName?: string
    lastName?: string
    email?: string
    status?: string
    role?: string
  }
}

export type inviteUserPayloadType = fetchUserPayloadType & {
  payload: {
    roleId: string
    firstName: string
    lastName: string
    email: string
    mobileNo: string
  }
}

export type updateUserDetailsPayloadType = fetchUserPayloadType & {
  payload: {
    userId: string
    firstName?: string
    lastName?: string
    email?: string
    updatedStatus?: string
  }
}

export type updateUserRolePayloadType = fetchUserPayloadType & {
  payload: {
    userId: string
    roleId: string
  }
}

export type deleteUserRolePayloadType = fetchUserPayloadType & {
  payload: {
    userId: string
    roleId: string
  }
}

export const usersSlice = createSlice({
  name: "users",
  initialState: usersInitialState,
  reducers: {
    fetchUsers: (state, action: fetchUserPayloadType) => {
      state.isLoading = true
    },
    fetchUsersSuccess: (
      state,
      { payload }: { payload: { users: []; totalRowCount: number } },
    ) => {
      state.isLoading = false
      state.users = payload.users
      state.pageInfo.totalRowCount = payload.totalRowCount
    },
    fetchUsersFailed: (state) => {
      state.isLoading = false
    },

    fetchRoles: (state) => {
      // Empty function body
    },
    fetchRolesSuccess: (state, { payload }: { payload: { roles: [] } }) => {
      state.roles = payload.roles
    },
    fetchRolesFailed: (state) => {
      // Empty function body
    },

    inviteUser: (state, action: inviteUserPayloadType) => {
      state.isLoading = true
    },
    inviteUserSuccess: (
      state,
      { payload }: { payload: { users: []; totalRowCount: number } },
    ) => {
      state.isLoading = false
      state.users = payload.users
    },
    inviteUserFailed: (state) => {
      state.isLoading = false
    },

    updateUserDetails: (state, action: updateUserDetailsPayloadType) => {
      state.isLoading = true
    },
    updateUserDetailsSuccess: (
      state,
      { payload }: { payload: { users: []; totalRowCount: number } },
    ) => {
      state.isLoading = false
      state.users = payload.users
    },
    updateUserDetailsFailed: (state) => {
      state.isLoading = false
    },

    updateUserRole: (state, action: updateUserRolePayloadType) => {
      state.isLoading = true
    },
    updateUserRoleSuccess: (
      state,
      { payload }: { payload: { users: []; totalRowCount: number } },
    ) => {
      state.isLoading = false
      state.users = payload.users
    },
    updateUserRoleFailed: (state) => {
      state.isLoading = false
    },

    deleteUserRole: (state, action: deleteUserRolePayloadType) => {
      state.isLoading = true
    },
    deleteUserRoleSuccess: (
      state,
      { payload }: { payload: { users: []; totalRowCount: number } },
    ) => {
      state.isLoading = false
      state.users = payload.users
    },
    deleteUserRoleFailed: (state) => {
      state.isLoading = false
    },
    setFilter: (state, { payload }) => {
      state.filters = payload
    },
    resetFilter: (state) => {
      state.filters = initialFilterState
    },
    setPage(state, action) {
      state.pagination.page = action.payload
    },
  },
})

export const {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailed,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFailed,
  inviteUser,
  inviteUserSuccess,
  inviteUserFailed,
  updateUserDetails,
  updateUserDetailsSuccess,
  updateUserDetailsFailed,
  updateUserRole,
  updateUserRoleSuccess,
  updateUserRoleFailed,
  deleteUserRole,
  deleteUserRoleSuccess,
  deleteUserRoleFailed,
  setFilter,
  resetFilter,
  setPage,
} = usersSlice.actions

export const selectUsers = (state: RootState) => state.users

export default usersSlice.reducer
