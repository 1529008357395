import React, { useState } from "react"
import {
  TextField,
  Button,
  Stack,
  Typography,
  Box,
  Autocomplete,
} from "@mui/material"
import { selectCenters } from "../../slices/centersSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  addMeetingRoom,
  updateMeetingRoom,
} from "../../slices/meetingRoomsSlice"
import PageHeader from "../../components/PageHeader/PageHeader"
import ElevatedCard from "../../components/ElevatedCard/ElevatedCard"
import { useLocation, useNavigate } from "react-router-dom"
import FileUploadCarousel from "../../components/FileUploadCrousel/FileUploadCrousel"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { MEETING_ROOMS } from "../../constants/path"

const Amenities = [
  "Projector",
  "Whiteboard",
  "Video Conferencing",
  "Air Conditioning",
  "TV",
]

const Capacity = ["2", "4", "6", "8", "10"]

interface FormValues {
  centerId: string
  meetingRoomName: string
  seatCount: string
  pricePerHour: number
  description: string
  rules: string
}

const AddMeetingRoomForm = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()

  const centersData = useAppSelector(selectCenters)
  const navigate = useNavigate()

  const [amenities, setAmenities] = useState<string[]>(
    state?.meetingRoom?.amenities || [],
  )
  const [formValues, setFormValues] = useState<FormValues>({
    centerId: state?.centerId || state?.meetingRoom?.center_id || "",
    meetingRoomName: state?.meetingRoom?.name || "",
    seatCount: state?.meetingRoom?.capacity || "2",
    pricePerHour: state?.meetingRoom?.price_per_half_hour || 0,
    description: state?.meetingRoom?.description || "",
    rules: state?.meetingRoom?.rules || "",
  })
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [remoteImages, setRemoteImages] = useState<string[]>(
    state?.meetingRoom?.images || [],
  )

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  // input change handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }))
  }

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    state?.meetingRoom
      ? dispatch(
          updateMeetingRoom({
            ...formValues,
            amenities,
            meetingRoomId: state?.meetingRoom?.id,
            images: remoteImages,
            newImages: selectedFiles,
          }),
        )
      : dispatch(
          addMeetingRoom({
            ...formValues,
            amenities,
            files: selectedFiles,
          }),
        )
  }

  return (
    <Box>
      <PageHeader
        title={`${
          state?.meetingRoom ? "Update Meeting Room" : "Add Meeting Room"
        }`}
        breadcrums={[
          { title: "Meeting Rooms", path: MEETING_ROOMS },
          { title: state?.meetingRoom ? "Add" : "Update" },
        ]}
      />
      <ElevatedCard style={{ width: isSmallScreen ? "84%" : "508px" }}>
        <Stack component="form" gap={2} onSubmit={handleSubmit}>
          <Typography variant="h6">
            {state?.meetingRoom
              ? "Update Meeting Room Details"
              : "Add Meeting Room Details"}
          </Typography>
          <Autocomplete
            id="center-name"
            options={centersData.centers}
            getOptionLabel={(option) => option.name}
            value={
              centersData.centers.find(
                (center) => center.id === formValues.centerId,
              ) || null
            }
            onChange={(_, newValue) => {
              setFormValues((prevValues) => ({
                ...prevValues,
                centerId: newValue?.id || "",
              }))
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Center Name"
                variant="outlined"
                size="small"
                required
              />
            )}
            readOnly
          />

          <TextField
            name="meetingRoomName"
            size="small"
            label="Meeting Room Name"
            value={formValues.meetingRoomName}
            onChange={handleInputChange}
            required
          />

          <Autocomplete
            id="seat-count"
            options={Capacity}
            value={formValues.seatCount}
            onChange={(_, newValue) => {
              setFormValues((prevValues) => ({
                ...prevValues,
                seatCount: newValue || "2",
              }))
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="No. of Seats"
                variant="outlined"
                size="small"
                required
              />
            )}
          />

          <Autocomplete
            id="amenities"
            options={Amenities}
            value={amenities}
            onChange={(_, newValue) => {
              setAmenities(newValue || [])
            }}
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                label="Amenities"
                variant="outlined"
                size="small"
              />
            )}
          />

          <TextField
            type="number"
            name="pricePerHour"
            size="small"
            label="Price/Half-Hour"
            value={formValues.pricePerHour}
            onChange={handleInputChange}
            required
          />
          <TextField
            multiline
            rows={2}
            maxRows={4}
            name="description"
            label="Description"
            value={formValues.description}
            onChange={handleInputChange}
            size="small"
          />
          <TextField
            multiline
            rows={2}
            maxRows={4}
            name="rules"
            label="Rules"
            value={formValues.rules}
            onChange={handleInputChange}
            size="small"
          />
          <FileUploadCarousel
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            remoteImages={remoteImages}
            setRemoteImages={setRemoteImages}
          />
          <Stack direction="row" gap={10}>
            <Button
              sx={{ flexGrow: 1 }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              sx={{ flexGrow: 1 }}
              onClick={() => navigate(-1)}
              variant="contained"
              color="secondary"
            >
              Back
            </Button>
          </Stack>
        </Stack>
      </ElevatedCard>
    </Box>
  )
}

export default AddMeetingRoomForm
