import { Box, Skeleton, Stack } from "@mui/material"
import { ListItemCard, ListWrapper } from "./styles"
import { fetchCenters, selectCenters } from "../../slices/centersSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useNavigate } from "react-router-dom"
import { CENTERS } from "../../constants/path"
import { useEffect } from "react"
import PageHeader from "../../components/PageHeader/PageHeader"
import { selectLogin } from "../../slices/loginSlice"
import { UserRole } from "../../constants/data"

const loadingCardsArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const Centers = () => {
  // centers store data and typed dispatch function
  const centersData = useAppSelector(selectCenters)
  const { role } = useAppSelector(selectLogin)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCenters())
  }, [dispatch])

  return (
    <Box>
      <PageHeader title="Centers" />
      {centersData.isCenterLoading && (
        <ListWrapper>
          {loadingCardsArr.map((item) => (
            <ListItemCard
              key={item}
              style={{ cursor: "auto", boxShadow: "none", transform: "none" }}
            >
              <Stack gap={2}>
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
              </Stack>
            </ListItemCard>
          ))}
        </ListWrapper>
      )}
      <ListWrapper>
        {role === UserRole.ADMIN && !centersData.isCenterLoading && (
          <ListItemCard onClick={() => navigate(`${CENTERS}/add`)}>
            Add New
          </ListItemCard>
        )}
        {centersData.centers.map((center) => (
          <ListItemCard
            key={center.id}
            onClick={() => navigate(`${CENTERS}/details/${center.id}`)}
          >
            {center.name}
          </ListItemCard>
        ))}
      </ListWrapper>
    </Box>
  )
}

export default Centers
