import { takeLatest, put, call } from "redux-saga/effects"
import {
  fetchMeetingRoomsService,
  addMeetingRoomService,
  updateMeetingRoomService,
} from "../services/meetingRoomsService"
import {
  fetchMeetingRooms,
  fetchMeetingRoomsFailed,
  fetchMeetingRoomsPayloadType,
  fetchMeetingRoomsSuccess,
  addMeetingRoom,
  addMeetingRoomSuccess,
  addMeetingRoomFailed,
  addMeetingRoomPayloadType,
  updateMeetingRoomFailed,
  updateMeetingRoomPayloadType,
  updateMeetingRoomSuccess,
  updateMeetingRoom,
} from "../slices/meetingRoomsSlice"
import { showToast } from "../slices/toastSlice"

export function* fetchMeetingRoomsSaga(action: fetchMeetingRoomsPayloadType) {
  try {
    const { data } = yield call(fetchMeetingRoomsService, action)
    yield put(
      fetchMeetingRoomsSuccess({
        meetingRooms: data?.data || [],
      }),
    )
  } catch (err) {
    yield put(fetchMeetingRoomsFailed())
  }
}

export function* addMeetingRoomSaga(action: addMeetingRoomPayloadType) {
  try {
    yield call(addMeetingRoomService, action)
    yield put(addMeetingRoomSuccess())
    yield put(
      showToast({
        toastType: "success",
        msg: "Meeting Room Added Successfully",
      }),
    )
  } catch (err) {
    yield put(addMeetingRoomFailed())
  }
}

export function* updateMeetingRoomSaga(action: updateMeetingRoomPayloadType) {
  try {
    yield call(updateMeetingRoomService, action)
    yield put(updateMeetingRoomSuccess())
    yield put(
      showToast({
        toastType: "success",
        msg: "Meeting Room Updated Successfully",
      }),
    )
  } catch (err) {
    yield put(updateMeetingRoomFailed())
  }
}

export default function* meetingRoomsSaga() {
  yield takeLatest(fetchMeetingRooms, fetchMeetingRoomsSaga)
  yield takeLatest(addMeetingRoom, addMeetingRoomSaga)
  yield takeLatest(updateMeetingRoom, updateMeetingRoomSaga)
}
