import { IconButton, styled, Typography } from "@mui/material"
import theme from "../../app/theme"

export const DialogContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: white;
`

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`

export const DialogDataRow = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media (max-width: ${theme.breakpoints.values["xs"]}px) {
    flex-direction: column;
  }
`

export const DialogDataHeading = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  width: 150px;
`

export const DialogData = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  flex: 1;
`
