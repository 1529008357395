import axiosConfig from "../app/axiosConfig"
import {
  MEETING_ROOM_UPDATE_BY_CENTER_ID_AND_MEETING_ROOM_ID_API,
  MEETING_ROOMS_BY_CENTER_ID_API,
} from "../constants/apis"
import {
  fetchMeetingRoomsPayloadType,
  updateMeetingRoomPayloadType,
} from "../slices/meetingRoomsSlice"
import { addMeetingRoomPayloadType } from "../slices/meetingRoomsSlice"
import { computeRoute } from "../utils/compute-route"

export const fetchMeetingRoomsService = ({
  payload,
}: fetchMeetingRoomsPayloadType) =>
  axiosConfig.get(
    computeRoute(MEETING_ROOMS_BY_CENTER_ID_API, { ...payload }),
    { params: { limit: 1000 } },
  )

export const addMeetingRoomService = ({
  payload,
}: addMeetingRoomPayloadType) => {
  const formData = new FormData()
  formData.append("type", "medium")
  formData.append("name", payload.meetingRoomName)
  formData.append("capacity", payload.seatCount)
  formData.append("price_per_half_hour", payload.pricePerHour.toString())
  formData.append("description", payload.description)
  formData.append("rules", payload.rules)

  // Append amenities as an array
  payload.amenities.forEach((amenity) => {
    formData.append(`amenities`, amenity)
  })

  // Append images
  payload.files.forEach((image) => {
    formData.append(`images`, image)
  })

  return axiosConfig.post(
    computeRoute(MEETING_ROOMS_BY_CENTER_ID_API, {
      centerId: payload.centerId,
    }),
    formData,
  )
}

export const updateMeetingRoomService = ({
  payload,
}: updateMeetingRoomPayloadType) => {
  const formData = new FormData()
  formData.append("type", "medium")
  formData.append("name", payload.meetingRoomName)
  formData.append("capacity", payload.seatCount)
  formData.append("price_per_half_hour", payload.pricePerHour.toString())
  formData.append("description", payload.description)
  formData.append("rules", payload.rules)

  // Append amenities as an array
  payload.amenities.forEach((amenity) => {
    formData.append(`amenities`, amenity)
  })

  // Append exsiting images an array
  payload.images.forEach((image) => {
    formData.append(`images`, image)
  })

  // Append new images
  payload.newImages.forEach((image) => {
    formData.append(`new_images`, image)
  })

  return axiosConfig.put(
    computeRoute(MEETING_ROOM_UPDATE_BY_CENTER_ID_AND_MEETING_ROOM_ID_API, {
      centerId: payload.centerId,
      meetingRoomId: payload.meetingRoomId,
    }),
    formData,
  )
}
