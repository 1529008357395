import styled from "@emotion/styled"

// Media Query for small screens (e.g., mobile)
const smallScreen = "@media (max-width: 768px)"

export const Wrapper = styled.div`
  flex-shrink: 0;
  background-color: white;
  width: 260px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: 400;
  transition: width 0.3s;

  &.collapsed {
    width: 60px;
  }

  ${smallScreen} {
    display: none;
  }
`

export const Logo = styled.img`
  height: 44px;
  padding: 20px 0px;
  margin: 0px auto;
  cursor: pointer;
`

export const CustomListItem = styled.div`
  height: 40px;
  align-items: center;
  display: flex;
  color: #394455;
  font-size: 14px;
  margin: 0px 10px;
  gap: 12px;
  -webkit-font-smoothing: antialiased;
  border-radius: 0.3rem;

  svg {
    height: 20px;
    margin-left: 8px;
  }

  :hover {
    background: #eee;
    cursor: pointer;
  }

  &.active {
    background: #fdb915;
    font-weight: 600;
    color: white;
  }
`
export const MenuButtonWrapper = styled.nav`
  position: fixed;
  top: 22px;
  left: 14px;
  z-index: 1001;
`
