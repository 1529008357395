import styled from "@emotion/styled"
import { Switch, Typography } from "@mui/material"

type SwitchProps = {
  label: string
  checked: boolean
  option: string[]
  disabled: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  id: string
}

function SwitchField({
  label,
  checked,
  option,
  disabled,
  onChange,
  id,
}: SwitchProps) {
  const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    opacity: ${disabled ? 0.4 : 1};
  `
  return (
    <SwitchWrapper id={id}>
      <Typography marginRight="1rem">{label}</Typography>
      <Typography>{option[0]}</Typography>
      <Switch
        id={id}
        checked={checked}
        onChange={(e) => onChange(e)}
        color="primary"
        disabled={disabled}
      />
      <Typography>{option[1]}</Typography>
    </SwitchWrapper>
  )
}

export default SwitchField
