import axiosConfig from "../app/axiosConfig"
import { USER_LOGIN_API, USERS_REFRESH_TOKEN_API } from "../constants/apis"

export const fetchLoginService = (email: string, password: string) =>
  axiosConfig.post(USER_LOGIN_API, {
    email,
    password,
  })

export const generateAccessTokenService = (refreshToken: string) =>
  axiosConfig.post(USERS_REFRESH_TOKEN_API, {
    token: refreshToken,
  })
