import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  getBankList,
  getPaymentMethods,
  getProcessSchedule,
  getTransactionList,
  PaginationType,
  resetFilters,
  selectTransactions,
  setFiltersFromURL,
  setPagination,
} from "../../slices/transactionsSlice"
import PageHeader from "../../components/PageHeader/PageHeader"
import { Stack, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { TransactionTable } from "./TransactionTable"
import UploadPopup from "./UploadPopup"
import TransactionsFilters from "./TransactionsFilter"
import { useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"
import { constructApiRequestParams } from "../../utils/transactionHelpers"
import { allowedOnlyBankAccountsRoles } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"

export const Transactions = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"))
  const {
    transactionList,
    pageInfo,
    isLoading,
    filters,
    bankList,
    pagination,
  } = useAppSelector(selectTransactions)
  const { role } = useAppSelector(selectLogin)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)

  useEffect(() => {
    dispatch(getBankList())
    dispatch(getPaymentMethods())

    // URL Parameters to Redux State
    const params = queryString.parse(location.search)
    if (Object.keys(params).length !== 0) {
      // URL has filter parameters
      if (!Array.isArray(params.page) && !Array.isArray(params.pageSize)) {
        const page = parseInt(params.page || "0")
        const pageSize = parseInt(params.pageSize || "10")
        dispatch(setPagination({ page, pageSize }))
      }
      const updatedFilters = { ...filters, ...params }
      dispatch(setFiltersFromURL(updatedFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // set filters to URL for sharing purpose
    const params = queryString.stringify({ ...filters, ...pagination })
    navigate({ search: params })
    dispatch(getTransactionList(constructApiRequestParams(filters, pagination)))
    if (allowedOnlyBankAccountsRoles.includes(role)) {
      dispatch(getProcessSchedule())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, pagination])

  // reset filter handler
  const resetFilterHandler = () => {
    dispatch(resetFilters())
  }

  //handle refresh
  const handleRefresh = () => {
    dispatch(getTransactionList(constructApiRequestParams(filters, pagination)))
    if (allowedOnlyBankAccountsRoles.includes(role)) {
      dispatch(getProcessSchedule())
    }
  }

  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true)
  }

  const handlesetUploadDialogClose = () => {
    setUploadDialogOpen(false)
  }

  const setPaginationModel = (page: PaginationType) => {
    dispatch(setPagination(page))
  }

  return (
    <>
      <PageHeader title="Transactions" />
      <Stack mx={1} width="97%" margin="auto" gap={1} mt={1} pb={6}>
        <TransactionsFilters
          handleRefresh={handleRefresh}
          isMediumScreen={isMediumScreen}
          isSmallScreen={isSmallScreen}
          resetFilterHandler={resetFilterHandler}
          handleUploadDialogOpen={handleUploadDialogOpen}
        />
        <TransactionTable
          transactions={transactionList}
          isLoading={isLoading}
          pageInfo={pageInfo}
          paginationModel={pagination}
          setPaginationModel={setPaginationModel}
        />
        {uploadDialogOpen && (
          <UploadPopup
            open={uploadDialogOpen}
            onClose={handlesetUploadDialogClose}
            bankList={bankList}
          />
        )}
      </Stack>
    </>
  )
}
