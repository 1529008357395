import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import PageHeader from "../../components/PageHeader/PageHeader"
import { useNavigate, useParams } from "react-router-dom"
import { BANK_ACCOUNTS } from "../../constants/path"
import ElevatedCard from "../../components/ElevatedCard/ElevatedCard"
import { Formik, FormikProps } from "formik"
import { Bank } from "../../models/bank.model"
import Form from "./Form"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  createBankAccount,
  getBankDetails,
  resetCreateAccountState,
  selectBankAccounts,
} from "../../slices/bankAccountsSlice"
import { useEffect } from "react"

export interface FormInterface {
  accountName: string
  accountNumber: string
  bankName: string
  paymentMode: string
  owner: string
  invoiceSource: string
  zohoAccountId: string
}

const initialFormValues: FormInterface = {
  accountName: "",
  accountNumber: "",
  bankName: "",
  paymentMode: "netbanking",
  owner: "",
  invoiceSource: "",
  zohoAccountId: "",
}

const AddBankAccount = () => {
  const { bankAccountId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { createAccount, isBankDetailsLoading, bankDetails } =
    useAppSelector(selectBankAccounts)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    if (bankAccountId && bankAccountId !== "add")
      dispatch(getBankDetails({ accountId: bankAccountId }))
  }, [bankAccountId, dispatch])

  useEffect(() => {
    if (createAccount.isCreated) {
      navigate(BANK_ACCOUNTS)
    }
    return () => {
      dispatch(resetCreateAccountState())
    }
  }, [navigate, createAccount.isCreated, dispatch])

  const handleSubmit = (values: FormInterface) => {
    dispatch(
      createBankAccount(
        bankAccountId !== "add" ? { ...values, bankAccountId } : values,
      ),
    )
  }

  return (
    <Box>
      <PageHeader
        title={
          bankAccountId !== "add" ? "Update Bank Account" : "Add Bank Account"
        }
        breadcrums={[
          { title: "Bank Accounts", path: BANK_ACCOUNTS },
          { title: bankAccountId !== "add" ? "Update" : "Add" },
        ]}
      />
      <ElevatedCard style={{ width: isSmallScreen ? "84%" : "508px" }}>
        <Typography variant="h6" mb={2}>
          {bankAccountId !== "add"
            ? "Update Bank Account Details"
            : "Add Bank Account Details"}
        </Typography>
        {isBankDetailsLoading ? (
          <Stack
            minHeight={430}
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Formik
            validationSchema={Bank.addBankFormSchema}
            initialValues={
              bankAccountId !== "add"
                ? {
                    accountName: bankDetails.accountName || "",
                    accountNumber: bankDetails.accountNumber || "",
                    bankName: bankDetails.bankName || "",
                    paymentMode: bankDetails.paymentMode || "",
                    owner: bankDetails.source || "",
                    invoiceSource: bankDetails.invoiceSource || "",
                    zohoAccountId: bankDetails.zohoAccountId || "",
                  }
                : initialFormValues
            }
            onSubmit={handleSubmit}
            validateOnMount={false}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(props: FormikProps<any>) => {
              return <Form {...props} isLoading={createAccount.isLoading} />
            }}
          </Formik>
        )}
      </ElevatedCard>
    </Box>
  )
}

export default AddBankAccount
