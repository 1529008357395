import React, { useState } from "react"
import { Button, Link, Menu, MenuItem, Tooltip } from "@mui/material"
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule"

export type options = {
  value: string
  name: string
}[]

export const ListingRowMenu = ({
  options,
  name,
}: {
  options: options
  name: string
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const optionsLength = options?.length

  if (optionsLength === 0) {
    return <HorizontalRuleIcon />
  }

  return optionsLength === 1 ? (
    <Link
      component="button"
      onClick={() => {
        window.open(options[0].value)
      }}
    >
      {options[0].name}
    </Link>
  ) : (
    <>
      <Tooltip title="Click to view">
        <Button
          id={`${name}-button`}
          aria-controls={open ? `${name}-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {`${optionsLength} ${name}(s)`}
        </Button>
      </Tooltip>
      <Menu
        id={`${name}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `${name}-button`,
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem onClick={() => window.open(option.value)}>
              {option.name}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
