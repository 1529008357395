import { styled } from "@mui/material"

export const ParentContainer = styled("div")`
  display: flex;
  height: 100vh;
  width: 100%;
  background: #f5f7fa;
  color: rgb(57, 68, 85);
`

export const MainContainer = styled("main")`
  height: 100vh;
  overflow: auto;
  flex: auto;
`
