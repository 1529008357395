import { Breadcrumbs, Link, Typography } from "@mui/material"
import { Wrapper } from "./styles"
import { NavLink } from "react-router-dom"

type BreadcrumsType = {
  title: string
  path?: string
}

type PageHeaderProps = {
  title: string
  breadcrums?: BreadcrumsType[]
}

const PageHeader = ({ title, breadcrums = [] }: PageHeaderProps) => {
  return (
    <Wrapper>
      <Typography variant="h5">{title}</Typography>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrums.map((breadcrum, index) =>
          breadcrum.path ? (
            <Link
              component={NavLink}
              to={breadcrum.path}
              underline="hover"
              color="primary"
              key={index}
            >
              {breadcrum.title}
            </Link>
          ) : (
            <Typography key={index}>{breadcrum.title}</Typography>
          ),
        )}
      </Breadcrumbs>
    </Wrapper>
  )
}

export default PageHeader
