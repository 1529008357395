import { takeLatest, put, call } from "redux-saga/effects"
import {
  bookDaypass,
  bookDaypassSuccess,
  bookDaypassFailed,
  bookDaypassPayloadType,
  fetchDaypassEnabledCentersSuccess,
  fetchDaypassEnabledCentersFailed,
  fetchDaypassEnabledCenters,
} from "../slices/daypassBookingSlice"
import { showToast } from "../slices/toastSlice"
import {
  bookDaypassService,
  fetchDaypassEnabledCentersService,
} from "../services/daypassService"

export function* bookDaypassSaga(action: bookDaypassPayloadType) {
  try {
    yield call(bookDaypassService, action)
    yield put(bookDaypassSuccess())
    yield put(
      showToast({
        toastType: "success",
        msg: "Booking Successfull",
      }),
    )
  } catch (err) {
    yield put(bookDaypassFailed())
  }
}

export function* fetchDaypassEnabledCentersSaga() {
  try {
    const { data } = yield call(fetchDaypassEnabledCentersService)
    yield put(
      fetchDaypassEnabledCentersSuccess({
        centers: data?.data || [],
      }),
    )
  } catch (err) {
    yield put(fetchDaypassEnabledCentersFailed())
  }
}

export default function* daypassBookingSaga() {
  yield takeLatest(bookDaypass, bookDaypassSaga)
  yield takeLatest(fetchDaypassEnabledCenters, fetchDaypassEnabledCentersSaga)
}
