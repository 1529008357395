import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"

type ToastState = {
  open: boolean
  toastType: string
  msg: string
}

export const toastInitialState: ToastState = {
  open: false,
  toastType: "success",
  msg: "",
}

export const toastSlice = createSlice({
  name: "toast",
  initialState: toastInitialState,
  reducers: {
    showToast: (
      state,
      {
        payload,
      }: {
        payload: { toastType: string; msg: string }
      },
    ) => {
      state.open = true
      state.toastType = payload.toastType
      state.msg = payload.msg
    },
    hideToast: (state) => {
      state.open = false
    },
  },
})

export const { showToast, hideToast } = toastSlice.actions

export const selectToast = (state: RootState) => state.toast

export default toastSlice.reducer
