import { takeLatest, put, call } from "redux-saga/effects"
import {
  fetchRolesService,
  fetchUsersService,
  inviteUserService,
  updateUserDetailsService,
  updateUserRoleService,
  deleteUserRoleService,
} from "../services/userService"
import {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailed,
  fetchUserPayloadType,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFailed,
  inviteUser,
  inviteUserSuccess,
  inviteUserFailed,
  inviteUserPayloadType,
  updateUserDetails,
  updateUserDetailsSuccess,
  updateUserDetailsFailed,
  updateUserDetailsPayloadType,
  updateUserRole,
  updateUserRoleSuccess,
  updateUserRoleFailed,
  updateUserRolePayloadType,
  deleteUserRole,
  deleteUserRoleSuccess,
  deleteUserRoleFailed,
  deleteUserRolePayloadType,
} from "../slices/usersSlice"
import { showToast } from "../slices/toastSlice"

export function* fetchUsersSaga(action: fetchUserPayloadType) {
  try {
    const { data } = yield call(fetchUsersService, action)
    yield put(
      fetchUsersSuccess({
        users: data.data || [],
        totalRowCount: data.pagination.total || 0,
      }),
    )
  } catch (err) {
    yield put(fetchUsersFailed())
  }
}

export function* fetchRolesSaga() {
  try {
    const { data } = yield call(fetchRolesService)
    yield put(fetchRolesSuccess({ roles: data.data || [] }))
  } catch (err) {
    yield put(fetchRolesFailed())
  }
}

export function* inviteUserSaga(action: inviteUserPayloadType) {
  try {
    yield call(inviteUserService, action)
    const { data } = yield call(fetchUsersService, action)
    yield put(
      inviteUserSuccess({
        users: data.data || [],
        totalRowCount: data.pagination.total || 0,
      }),
    )
  } catch (err) {
    yield put(inviteUserFailed())
  }
}

export function* updateUserDetailsSaga(action: updateUserDetailsPayloadType) {
  try {
    yield call(updateUserDetailsService, action)
    const { data } = yield call(fetchUsersService, action)
    yield put(
      updateUserDetailsSuccess({
        users: data.data || [],
        totalRowCount: data.pagination.total || 0,
      }),
    )
  } catch (err) {
    yield put(updateUserDetailsFailed())
  }
}

export function* updateUserRoleSaga(action: updateUserRolePayloadType) {
  try {
    yield call(updateUserRoleService, action)
    const { data } = yield call(fetchUsersService, action)
    yield put(
      updateUserRoleSuccess({
        users: data.data || [],
        totalRowCount: data.pagination.total || 0,
      }),
    )
    yield put(
      showToast({
        toastType: "success",
        msg: "User role updated Successfully",
      }),
    )
  } catch (err) {
    yield put(updateUserRoleFailed())
  }
}

export function* deleteUserRoleSaga(action: deleteUserRolePayloadType) {
  try {
    yield call(deleteUserRoleService, action)
    const { data } = yield call(fetchUsersService, action)
    yield put(
      deleteUserRoleSuccess({
        users: data.data || [],
        totalRowCount: data.pagination.total || 0,
      }),
    )
    yield put(
      showToast({
        toastType: "success",
        msg: "User role updated Successfully",
      }),
    )
  } catch (err) {
    yield put(deleteUserRoleFailed())
  }
}

export default function* usersSaga() {
  yield takeLatest(fetchUsers, fetchUsersSaga)
  yield takeLatest(fetchRoles, fetchRolesSaga)
  yield takeLatest(inviteUser, inviteUserSaga)
  yield takeLatest(updateUserDetails, updateUserDetailsSaga)
  yield takeLatest(updateUserRole, updateUserRoleSaga)
  yield takeLatest(deleteUserRole, deleteUserRoleSaga)
}
