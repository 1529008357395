import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"

const jwt = localStorage.getItem("jwt")
const role = localStorage.getItem("role") ? localStorage.getItem("role") : ""
const userName = localStorage.getItem("name")

type LoginState = {
  token: string | null
  role: string
  loading: boolean
  name: string | null
}

export const loginInitialState: LoginState = {
  token: jwt || null,
  role: role || "",
  loading: false,
  name: userName || null,
}

export type loginPayloadType = {
  payload: {
    email: string
    password: string
  }
}

export type generateAccessTokenPayloadType = {
  payload: {
    refreshToken: string
  }
}

export const loginSlice = createSlice({
  name: "login",
  initialState: loginInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login: (state, action: loginPayloadType) => {
      state.loading = true
    },
    loginSuccess: (
      state,
      { payload }: { payload: { token: string; role: string; name: string } },
    ) => {
      state.loading = false
      state.token = payload.token
      state.role = payload.role
      state.name = payload.name
    },
    loginFailed: (state) => {
      state.loading = false
    },

    logout: (state) => {
      state.token = null
    },
  },
})

export const { login, loginSuccess, loginFailed, logout } = loginSlice.actions

export const selectLogin = (state: RootState) => state.login

export default loginSlice.reducer
