import axiosConfig from "../app/axiosConfig"
import { CENTER_VERIFICATION_LOGS_API } from "../constants/apis"
import { fetchCenterLogsPayloadType } from "../slices/auditLogsSlice"

export const fetchCenterLogsService = ({
  payload,
}: fetchCenterLogsPayloadType) =>
  axiosConfig.get(CENTER_VERIFICATION_LOGS_API, {
    params: {
      limit: payload.pageSize,
      page: payload.page + 1,
      status: payload.status,
      start_datetime: payload.startDate,
      end_datetime: payload.endDate,
      user_id: payload.userId ? payload.userId : null,
      center_id: [payload.centerId],
      ...(payload.gatekeeper !== "" &&
        payload.gatekeeper !== "none" && {
          gatekeeper_id: payload.gatekeeper,
        }),
    },
  })
