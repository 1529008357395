import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import dayjs, { Dayjs } from "dayjs"
import { DaypassType, PaymentStatus } from "../constants/data"

type bookingListType = {
  daypass_type: string
  booking_code: string
  booking_date: string
  invoice_url: string
  created_by: string | null
  payment_status: string
  user_details: {
    id: string
    first_name: string | null
    last_name: string | null
    mobile: string
  }
  center_details: {
    name: string
    working_hours_start: string
  }
  meeting_room_details: {
    name: string
  }
  day_passes_data: {
    created_at: string
  }
}

type bookingDetailsType = {
  profile_image: string
  first_name: string
  last_name: string
  email: string
  mobile: string
  kyc_image: string
  status: string
  id: string
  roles: any
}

type FilterType = {
  dateSelector: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  center?: string | null
  type?: string[]
  isActive?: boolean | null
  paymentStatus?: string[]
  bookingRef?: string | null
}

type BookingHistoryState = {
  bookingList: bookingListType[]
  isLoading: boolean
  bookingDetails: bookingDetailsType
  isDetailsLoading: boolean
  pageInfo: {
    pageNo: number
    pageSize: number
    totalPages: number
  }
  filters: FilterType
}

const initialFilterState = {
  dateSelector: "month",
  startDate: dayjs().startOf("month"),
  endDate: dayjs(),
  type: [DaypassType.DAYPASS, DaypassType.BULKDAYPASS],
  center: "All",
  isActive: null,
  paymentStatus: [PaymentStatus.PAID],
  bookingRef: "none",
}

export const bookingHistoryInitialState: BookingHistoryState = {
  bookingList: [],
  isLoading: false,
  bookingDetails: {
    profile_image: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    kyc_image: "",
    status: "",
    id: "",
    roles: [],
  },
  isDetailsLoading: false,
  pageInfo: {
    pageNo: 1,
    pageSize: 10,
    totalPages: 0,
  },
  filters: initialFilterState,
}

export type fetchBookingListPayloadType = {
  payload: {
    type?: string[]
    pageNo: number
    pageSize: number
    startDate?: string
    endDate?: string
    center?: string | null
    isActive?: boolean | null
    paymentStatus?: string[] | null
    bookingRef?: string | null
    userId?: string
  }
}

export type fetchBookingDetailsPayloadType = {
  payload: {
    userId: string
  }
}

export const bookingHistorySlice = createSlice({
  name: "bookingHistory",
  initialState: bookingHistoryInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchBookingList: (state, action: fetchBookingListPayloadType) => {
      state.bookingList = []
      state.isLoading = true
    },
    fetchBookingListSuccess: (
      state,
      { payload }: { payload: { bookingList: []; totalPages: number } },
    ) => {
      state.bookingList = payload.bookingList
      state.pageInfo.totalPages = payload.totalPages
      state.isLoading = false
    },
    fetchBookingListFailed: (state) => {
      state.isLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchBookingDetails: (state, action: fetchBookingDetailsPayloadType) => {
      state.isDetailsLoading = true
    },
    fetchBookingDetailsSuccess: (
      state,
      { payload }: { payload: { bookingDetails: bookingDetailsType } },
    ) => {
      state.bookingDetails = payload.bookingDetails
      state.isDetailsLoading = false
    },
    fetchBookingDetailsFailed: (state) => {
      state.isDetailsLoading = false
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    resetFilters: (state) => {
      state.filters = initialFilterState
      state.pageInfo.pageNo = 1
    },
    setPage: (state, { payload }) => {
      state.pageInfo.pageNo = payload
    },
    resetBookingDetails: (state) => {
      state.isDetailsLoading = false
      state.bookingDetails = {
        profile_image: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        kyc_image: "",
        status: "",
        id: "",
        roles: [],
      }
    },
  },
})

export const {
  fetchBookingList,
  fetchBookingListSuccess,
  fetchBookingListFailed,
  fetchBookingDetails,
  fetchBookingDetailsSuccess,
  fetchBookingDetailsFailed,
  setFilters,
  setPage,
  resetFilters,
  resetBookingDetails,
} = bookingHistorySlice.actions

export const selectBookingHistory = (state: RootState) => state.bookingHistory

export default bookingHistorySlice.reducer
