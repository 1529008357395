// StyledDataGrid.js
import styled from "@emotion/styled"
import { DataGrid } from "@mui/x-data-grid"

const StyledDataGrid = styled(DataGrid)`
  /* Set base color to white for the entire table */
  background-color: white;

  /* Style for alternate rows */
  .MuiDataGrid-row:nth-of-type(even) {
    background-color: #f2f2f2;
  }

  /* Hover styles for all rows */
  .MuiDataGrid-row:hover {
    background-color: #e0e0e0;
  }

  /* Set the header and footer color to white */
  .MuiDataGrid-toolbar,
  .MuiDataGrid-footer {
    background-color: white;
  }
`

export const StyledGridOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & .ant-empty-img-1 {
    fill: #aeb8c2;
  }

  & .ant-empty-img-2 {
    fill: #f5f5f7;
  }

  & .ant-empty-img-3 {
    fill: #dce0e6;
  }

  & .ant-empty-img-4 {
    fill: #fff;
  }

  & .ant-empty-img-5 {
    fill-opacity: 0.8;
    fill: #f5f5f5;
  }
`

export default StyledDataGrid
