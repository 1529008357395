import React, { useEffect } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import DateTimeWrapper from "../../components/DateTimeWrapper/DateTimeWrapper"
import dayjs, { Dayjs } from "dayjs"

interface DateRangeSelectorProps {
  dateSelector: string
  filterState: {
    dateSelector: string
    startDate: Dayjs | null
    endDate: Dayjs | null
  }
  updateFilterState: (newState: {
    dateSelector: string
    startDate?: Dayjs | null
    endDate?: Dayjs | null
  }) => void
  disable?: boolean
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  dateSelector,
  filterState,
  updateFilterState,
}) => {
  // Function to determine if the "Today" filter should prioritize yesterday's data
  function prioritizeTodayFilter() {
    if (
      filterState.dateSelector === "today" &&
      filterState.startDate &&
      !dayjs().isSame(filterState.startDate, "day")
    ) {
      updateFilterState({
        dateSelector: "yesterday",
        startDate: dayjs().subtract(1, "day").startOf("day"),
        endDate: dayjs().subtract(1, "day").endOf("day"),
      })
    }
  }

  useEffect(() => {
    prioritizeTodayFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  const handleDateRangeChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event
    // Update startDate and endDate based on selected date range
    switch (value) {
      case "today":
        updateFilterState({
          dateSelector: "today",
          startDate: dayjs(),
          endDate: dayjs(),
        })
        break
      case "yesterday":
        updateFilterState({
          dateSelector: "yesterday",
          startDate: dayjs().subtract(1, "day"),
          endDate: dayjs().subtract(1, "day"),
        })
        break
      case "7 days":
        updateFilterState({
          dateSelector: "7 days",
          startDate: dayjs().subtract(7, "day"),
          endDate: dayjs(),
        })
        break
      case "30 days":
        updateFilterState({
          dateSelector: "30 days",
          startDate: dayjs().subtract(30, "day"),
          endDate: dayjs(),
        })
        break
      case "week":
        updateFilterState({
          dateSelector: "week",
          startDate: dayjs().startOf("week"),
          endDate: dayjs(),
        })
        break
      case "month":
        updateFilterState({
          dateSelector: "month",
          startDate: dayjs().startOf("month"),
          endDate: dayjs(),
        })
        break
      case "quarter": {
        const currentMonth = dayjs().month()
        const quarterStartMonth = Math.floor(currentMonth / 3) * 3 // Calculate start month of current quarter
        const quarterStartDate = dayjs()
          .startOf("year")
          .month(quarterStartMonth) // Set start date to the calculated month
        updateFilterState({
          dateSelector: "quarter",
          startDate: quarterStartDate,
          endDate: dayjs(),
        })
        break
      }
      case "custom":
        updateFilterState({
          dateSelector: "custom",
        })
        break
    }
  }

  const renderCustomDatePickers = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DateTimeWrapper style={{ width: "100%" }}>
          <DatePicker
            label="Start Date"
            format="DD/MM/YYYY"
            value={filterState.startDate ? dayjs(filterState.startDate) : null}
            onChange={(newValue) =>
              updateFilterState({
                ...filterState,
                startDate: newValue,
              })
            }
            sx={{ width: "100%" }}
          />
        </DateTimeWrapper>
      </DemoContainer>
      <DemoContainer components={["DatePicker"]}>
        <DateTimeWrapper style={{ width: "100%" }}>
          <DatePicker
            label="End Date"
            format="DD/MM/YYYY"
            value={filterState.endDate ? dayjs(filterState.endDate) : null}
            onChange={(newValue) =>
              updateFilterState({
                ...filterState,
                endDate: newValue,
              })
            }
            sx={{ width: "100%" }}
          />
        </DateTimeWrapper>
      </DemoContainer>
    </LocalizationProvider>
  )

  return (
    <>
      <FormControl
        fullWidth
        style={{ paddingTop: dateSelector === "custom" ? "8px" : 0 }}
        size="small"
      >
        <InputLabel id="date-range">Date Range</InputLabel>
        <Select
          labelId="date-range"
          id="date-range-id"
          value={dateSelector}
          label="Date Range"
          onChange={handleDateRangeChange}
        >
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="yesterday">Yesterday</MenuItem>
          <MenuItem value="week">This Week</MenuItem>
          <MenuItem value="month">This Month</MenuItem>
          <MenuItem value="quarter">This Quarter</MenuItem>
          <MenuItem value="7 days">Last 7 days</MenuItem>
          <MenuItem value="30 days">Last 30 days</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
        </Select>
      </FormControl>

      {dateSelector === "custom" && renderCustomDatePickers()}
    </>
  )
}

export default DateRangeSelector
