import React, { useCallback, useEffect, useState } from "react"
import Button from "@mui/material/Button"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Carousel from "react-material-ui-carousel"
import { Stack } from "@mui/material"
import { BASE_API } from "../../constants/apis"

type FileUploadCarouselProps = {
  selectedFiles: File[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>
  remoteImages: string[]
  setRemoteImages: React.Dispatch<React.SetStateAction<string[]>>
}

const FileUploadCarousel: React.FC<FileUploadCarouselProps> = ({
  selectedFiles,
  setSelectedFiles,
  remoteImages,
  setRemoteImages,
}) => {
  const [previewFiles, setPreviewFiles] = useState<
    { img: string; isRemote: boolean; id: number }[]
  >([])
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || [])
    const newUploadedFiles: File[] = [] // array to store files after checking duplicates

    // We are allowig multiple files upload, so we need to check each selected file for duplicates
    for (let i = 0; i < newFiles.length; i++) {
      const newFile = newFiles[i]
      const isDuplicate = selectedFiles.some(
        (selectedFile) =>
          selectedFile.name === newFile.name &&
          selectedFile.size === newFile.size,
      )

      if (!isDuplicate) {
        newUploadedFiles.push(newFile)
      }
    }
    setSelectedFiles((prevFiles) => [...newUploadedFiles, ...prevFiles])
    event.target.value = ""
  }

  const updatePreviewFiles = useCallback(() => {
    const localFiles = selectedFiles.map((file, index) => ({
      img: URL.createObjectURL(file),
      isRemote: false,
      id: index,
    }))
    const remoteFiles = remoteImages.map((src, index) => ({
      img: src,
      isRemote: true,
      id: index,
    }))
    setPreviewFiles([...localFiles, ...remoteFiles])
  }, [selectedFiles, remoteImages])

  useEffect(() => {
    updatePreviewFiles() // Call the memoized function when dependencies change
  }, [updatePreviewFiles]) // Ensure that the effect is triggered by changes to the memoized function itself

  const handleRemove = (index: number, isRemote: boolean) => {
    if (isRemote) {
      const updatedRemoteImages = [...remoteImages]
      updatedRemoteImages.splice(index, 1)
      setRemoteImages(updatedRemoteImages)
    } else {
      const updatedLocalImages = [...selectedFiles]
      updatedLocalImages.splice(index, 1)
      setSelectedFiles(updatedLocalImages)
    }
  }

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="file-upload-input"
        type="file"
        multiple
        onChange={handleFileChange}
      />
      <label htmlFor="file-upload-input">
        <Button
          style={{ width: "100%", marginBottom: "10px" }}
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Upload Files
        </Button>
      </label>
      <Carousel interval={8000} animation="slide">
        {previewFiles.map((imgObj, index) => (
          <>
            <Stack key={index} gap={1}>
              <img
                src={imgObj.isRemote ? `${BASE_API}/${imgObj.img}` : imgObj.img}
                alt={`Uploaded ${index}`}
                height="300px"
                style={{ objectFit: "contain" }}
              />
              <Button
                variant="outlined"
                onClick={() => handleRemove(imgObj.id, imgObj.isRemote)}
              >
                Remove
              </Button>
            </Stack>
          </>
        ))}
      </Carousel>
    </div>
  )
}

export default FileUploadCarousel
