import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fdb915",
      dark: "#c68900",
      light: "#ffed4d",
      contrastText: "#fff",
    },
    secondary: {
      main: "#6D5c3F",
      dark: "#4b3e2d",
      light: "#927b5f",
      contrastText: "#fff",
    },
    background: {
      default: "#E5ECF7",
      paper: "#F9FDFF",
    },
    error: {
      main: "#FF5630",
      light: "#FFCABD",
      dark: "#BF2600",
    },
    success: {
      main: "#36B37E",
      light: "#C9F8E1",
      dark: "#006644",
    },
  },
  typography: {
    fontFamily: ["Mulish", "Helvetica", "Helvetica Neue", "sans-serif"].join(
      ",",
    ),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  breakpoints: {
    values: {
      xs: 400,
      sm: 768,
      md: 1280,
      lg: 1440,
      xl: 2160,
    },
  },
})

export default theme
