import { useEffect, useState } from "react"
import Table from "../../components/Table/Table"
import { Transaction } from "../../models/transactions.model"
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material"
import { GridColDef, GridRowsProp } from "@mui/x-data-grid"
import dayjs from "dayjs"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import BalanceIcon from "@mui/icons-material/Balance"
import TransactionDetail from "./TransactionDetail"
import ReconcileForm from "../ReconcileForm/Index"
import { ListingRowMenu } from "../../components/ListingRowMenu.tsx"
import { allowedOnlyBankAccountsRoles } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"
import { useAppSelector } from "../../app/hooks"

interface TransactionTableProps {
  transactions: Transaction[]
  isLoading: boolean
  pageInfo: any
  paginationModel: any
  setPaginationModel: React.Dispatch<React.SetStateAction<any>>
}

export const TransactionTable = ({
  transactions,
  isLoading,
  pageInfo,
  paginationModel,
  setPaginationModel,
}: TransactionTableProps) => {
  const { role } = useAppSelector(selectLogin)
  const [rowCountState, setRowCountState] = useState(
    pageInfo?.totalRowCount || 0,
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const [reconcileDialogOpen, setReconcileDialogOpen] = useState(false)

  const [rowIndex, setRowIndex] = useState<number>(0)

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState,
    )
  }, [pageInfo?.totalRowCount, setRowCountState])

  const handleDialogOpen = (index: number) => {
    setRowIndex(index)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleReconcileDialogOpen = (index: number) => {
    setRowIndex(index)
    setReconcileDialogOpen(true)
  }

  const handleReconcileDialogClose = () => {
    setReconcileDialogOpen(false)
  }

  const columns: GridColDef[] = [
    {
      field: "info",
      headerName: "",
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={() => handleDialogOpen(row.rowIndex)}>
            <InfoOutlinedIcon />
          </IconButton>
        )
      },
    },
    { field: "id", headerName: "ID", width: 90, sortable: false },
    { field: "date", headerName: "Date", width: 120, sortable: false },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      sortable: false,
    },
    {
      field: "reconcile",
      headerName: "",
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row.isMarked ||
          row.isReconciled ||
          row.type !== "C" ||
          row.isSynced === false ? null : (
          <Tooltip title="Reconcile Manually">
            <IconButton onClick={() => handleReconcileDialogOpen(row.rowIndex)}>
              <BalanceIcon />
            </IconButton>
          </Tooltip>
        )
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      width: 150,
      sortable: false,
    },
    { field: "type", headerName: "Type", width: 120, sortable: false },
    {
      field: "referenceNo",
      headerName: "Reference No",
      width: 200,
      sortable: false,
    },
    {
      field: "valueDate",
      headerName: "Value Date",
      width: 150,
      sortable: false,
    },
    { field: "branch", headerName: "Branch", width: 150, sortable: false },
    {
      field: "balance",
      headerName: "Balance",
      type: "number",
      width: 150,
      sortable: false,
    },
    {
      field: "accountName",
      headerName: "Bank Name",
      width: 200,
      sortable: false,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return allowedOnlyBankAccountsRoles.includes(role) ? (
          <ListingRowMenu
            name="invoice"
            options={
              row?.settlements?.invoices?.length === 0
                ? []
                : row?.settlements?.invoices.map(
                    (invoice: {
                      invoice_url: string
                      invoice_number: string
                    }) => {
                      return {
                        value: invoice.invoice_url,
                        name: invoice.invoice_number,
                      }
                    },
                  )
            }
          />
        ) : (
          "NA"
        )
      },
    },
    {
      field: "isMarked",
      headerName: "Marked",
      type: "boolean",
      width: 120,
      sortable: false,
    },
    {
      field: "paymentId",
      headerName: "Payment Id",
      width: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Link
            component="button"
            onClick={() => {
              window.open(row.paymentURL)
            }}
          >
            {row.paymentId}
          </Link>
        )
      },
    },
    {
      field: "payee",
      headerName: "Payee",
      width: 200,
      sortable: false,
    },
  ]

  const rows: GridRowsProp = transactions.map((transaction, index) => ({
    rowIndex: index,
    id: transaction.id,
    date: transaction.date
      ? dayjs.utc(transaction.date).utcOffset("+05:30").format("DD/MM/YYYY")
      : "",
    description: transaction.description,
    amount: transaction.amount,
    type: transaction.type,
    referenceNo: transaction.referenceNo,
    valueDate: transaction.valueDate
      ? dayjs
          .utc(transaction.valueDate)
          .utcOffset("+05:30")
          .format("DD/MM/YYYY")
      : "",
    branch: transaction.branch,
    balance: transaction.balance,
    isMarked: transaction.isMarked,
    isReconciled: transaction.isReconciled,
    isSynced: transaction.isSynced,
    invoiceNumber: transaction.invoiceNumber,
    paymentId: transaction.paymentId,
    paymentURL: transaction.paymentURL,
    invoiceURL: transaction.invoiceURL,
    payee: transaction.payee,
    accountName: transaction.accountName,
    settlements: transaction.settlements,
  }))

  const columnVisibilityModel = {
    id: false,
    branch: false,
    balance: false,
    valueDate: false,
    paymentId: false,
  }

  return (
    <>
      {rows.length === 0 ? (
        <Box sx={{ textAlign: "center", marginTop: 20 }}>
          <Typography variant="body1">No data available</Typography>
        </Box>
      ) : (
        <Table
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={rowCountState}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          disableColumnFilter
          columnVisibilityModel={columnVisibilityModel}
        />
      )}
      {dialogOpen && (
        <TransactionDetail
          open={dialogOpen}
          onClose={handleDialogClose}
          transactionData={transactions[rowIndex]}
        />
      )}
      {reconcileDialogOpen && (
        <ReconcileForm
          open={reconcileDialogOpen}
          onClose={handleReconcileDialogClose}
          transactionData={transactions[rowIndex]}
        />
      )}
    </>
  )
}
