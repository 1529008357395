import { Routes, Route, Navigate } from "react-router-dom"
import React from "react"
import {
  ROOT,
  LOGIN,
  BOOKING_HISTORY,
  CENTERS,
  ADD_CENTER,
  MEETING_ROOMS,
  ADD_MEETING_ROOM,
  USERS,
  CENTER_DETAILS,
  AUDIT_LOGS,
  USER_PROFILE,
  TRANSACTIONS,
  USER_BOOKING_DETAILS,
  USER_AUDIT_LOG,
  BANK_ACCOUNTS,
  BANK_ACCOUNTS_VIEW,
} from "../constants/path"
import Login from "../containers/Login"
import BookingHistory from "../containers/BookingHistory"
import Centers from "../containers/Centers"
import MeetingRooms from "../containers/MeetingRooms"
import AddCenterForm from "../containers/AddCenterForm"
import AddMeetingRoomForm from "../containers/AddMeetingRoomForm"
import Users from "../containers/Users"
import CenterDetails from "../containers/CenterDetails"
import AuditLogs from "../containers/AuditLogs"
import {
  allowedOnlyBankAccountsRoles,
  allowedOnlyTrasactionsRoles,
  UserRole,
} from "../constants/data"
import { NotAuthorized, NotFound } from "../components/NotFound/NotFound"
import { Transactions } from "../containers/Transactions"
import UserProfile from "../containers/UserProfile"
import { BankAccounts } from "../containers/BankAccounts"
import AddBankAccount from "../containers/AddBankAccount"

type ProtectedRouteProps = {
  children: React.ReactNode
  token: string | null
  role: string
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  token,
  role,
}: ProtectedRouteProps) => {
  if (!token) {
    // user is not authenticated
    return <Navigate to={LOGIN} />
  }
  if (role === UserRole.ADMIN) {
    return <>{children}</>
  }
  const currentPath = window.location.pathname

  if (role === UserRole.AUDITOR) {
    const restrictedKeywords = ["add", TRANSACTIONS, BANK_ACCOUNTS]
    const isRestricted = restrictedKeywords.some((keyword) =>
      currentPath.includes(keyword),
    )
    if (isRestricted) {
      return <NotAuthorized />
    }
    return <>{children}</>
  }
  let ReturnVal = <NotAuthorized />

  if (allowedOnlyBankAccountsRoles.includes(role)) {
    const allowedBankKeyword = [BANK_ACCOUNTS]

    const isBankAllowed = allowedBankKeyword.some((keyword) =>
      currentPath.includes(keyword),
    )

    if (isBankAllowed) {
      ReturnVal = <>{children}</>
    }
  }

  if (allowedOnlyTrasactionsRoles.includes(role)) {
    const allowedTransactionKeyword = [TRANSACTIONS]
    const isTransactionAllowed = allowedTransactionKeyword.some((keyword) =>
      currentPath.includes(keyword),
    )

    if (isTransactionAllowed) {
      ReturnVal = <>{children}</>
    }
  }

  return ReturnVal
}

const routes = [
  { path: BOOKING_HISTORY, element: <BookingHistory /> },
  { path: USER_PROFILE, element: <UserProfile /> },
  { path: USER_BOOKING_DETAILS, element: <UserProfile /> },
  { path: USER_AUDIT_LOG, element: <UserProfile /> },
  { path: CENTERS, element: <Centers /> },
  { path: ADD_CENTER, element: <AddCenterForm /> },
  { path: CENTER_DETAILS, element: <CenterDetails /> },
  { path: MEETING_ROOMS, element: <MeetingRooms /> },
  { path: ADD_MEETING_ROOM, element: <AddMeetingRoomForm /> },
  { path: USERS, element: <Users /> },
  { path: AUDIT_LOGS, element: <AuditLogs /> },
  { path: TRANSACTIONS, element: <Transactions /> },
  { path: BANK_ACCOUNTS, element: <BankAccounts /> },
  { path: BANK_ACCOUNTS_VIEW, element: <AddBankAccount /> },
]

type AppRoutesProps = {
  token: string | null
  role: string
}

const AppRoutes: React.FC<AppRoutesProps> = ({
  token,
  role,
}: AppRoutesProps) => {
  return (
    <Routes>
      <Route path={ROOT} element={<Navigate to={LOGIN} />} />
      <Route path={LOGIN} element={<Login />} />
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <ProtectedRoute token={token} role={role}>
              {route.element}
            </ProtectedRoute>
          }
        />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
